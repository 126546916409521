import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { computed, action, observable, autorun } from 'mobx';
import { of } from 'rxjs';
import { map } from 'rxjs/internal/operators/map';
import { requests } from '../common/config/requests';
import { CityInput, CountryInput, StateInput } from '../common/data/countryStateCityInput';

@Injectable({ providedIn: 'root' })
export class MasterDataStore {
    @observable countriesList: Array<any> = [];
    @observable statesList: Array<any> = [];
    @observable citiesList: Array<any> = [];

    @observable selectedCountry: any;
    @observable selectedState: any;
    @observable selectedCity: string;


    constructor(public http: HttpClient) {
        if (window.localStorage['countries']) {
            let contries = JSON.parse(localStorage["countries"]);
            this.countriesList = [...contries];
        }
        autorun(() => {
            if (this.countriesList && this.countriesList.length > 0) {
                window.localStorage.setItem('countries', JSON.stringify(this.countriesList))
            }
            if (window.localStorage['countries']) {
                let user = JSON.parse(localStorage["countries"]);
                this.countriesList = [...user];
            }
        })
    }
    @computed get coumputedState() {
        return this.statesList;
    }
    @computed get coumputedCities() {
        return this.citiesList;
    }
    setSelectedCountry(isoCode) {
        this.selectedCountry = isoCode;
    }
    setSelectedState(isoCode) {
        this.selectedState = isoCode;
        if(this.selectedCountry && this.selectedState){
            this.getCity(this.selectedCountry,this.selectedState).subscribe();
        }
    }

    @computed get getCountries() {
        if (this.countriesList && this.countriesList.length > 0) {
            return this.countriesList
        } else {
            this.getC().subscribe();
            return this.countriesList;
        }
    }

    getC() {
        let opts = CountryInput;
        return this.http.post(requests.fillData, opts).pipe(map((res: any) => {
            this.countriesList = res?.result?.data?.properties?.data || []
            window.localStorage.setItem('countries', JSON.stringify(this.countriesList))
            return res?.result?.data?.properties?.data || [];
        }))
    }
    getState(code) {
        let opts = StateInput;
        opts.countryCode = code;
        return this.http.post(requests.fillData, opts).pipe(map((res: any) => {
            this.statesList = res?.result?.data?.properties?.data
            return res?.result?.data?.properties?.data || [];
        }))
    }

    getCity(countryCode, stateCode) {
        let opts = CityInput;
        opts.countryCode = this.countriesList && this.countriesList.find(x => x.name == countryCode || x.isoCode == countryCode) && this.countriesList.find(x => x.name == countryCode || x.isoCode == countryCode).isoCode;
        opts.stateCode = this.statesList && this.statesList.length > 0 && this.statesList.find(x => x.name == stateCode || x.code == stateCode) && this.statesList.find(x => x.name == stateCode || x.code == stateCode).code;
        if (opts.countryCode && opts.stateCode) {
            return this.http.post(requests.fillData, opts).pipe(map((res: any) => {
                this.citiesList = res?.result?.data?.properties?.data.map(x => { return { name: x.name } })
                return res?.result?.data?.properties?.data || [];
            }))
        } else {

            return of([])
        }
    }

    @action getStates(countryName) {
        if (countryName) {
            this.getState(this.countriesList && this.countriesList.find(x => x.name == countryName || x.isoCode == countryName).isoCode).subscribe();
            return this.statesList
        } else {
            return this.statesList || [];
        }
    }

    @action getCities(countryName, stateName) {

        if (countryName && stateName) {
            this.getCity(this.selectedCountry, this.selectedState).subscribe();
            return this.citiesList || [];

        } else {
            return [];
        }
    }
    // @computed get getSelectedStates() {
    //     // if (this.statesList) {
    //     //     return this.countriesList
    //     // } else {
    //         if(this.statesList && this.statesList.find(x=>x.isoCode==this.selectedCountry)){
    //             return this.statesList;    
    //         }else{

    //             this.statesList = csc.getStatesOfCountry(this.selectedCountry).map(x => {
    //                 return { name: x.name, code: x.isoCode }
    //             });
    //             return this.statesList;
    //         }
    //     }

    @computed get getSelectedStates() {
        //  if (this.selectedCountry) {
        // return csc.getStatesOfCountry(this.selectedCountry).map(x => {
        //     return { name: x.name, code: x.isoCode }
        // });
        // } else {
        return [];
        // }
    }
    // @computed get getSelectedCities(){

    //       this.citiesList= csc.getCitiesOfState(this.selectedCountry, this.selectedState).map(x => { return { name: x.name } });
    //       return this.citiesList

    // }


}
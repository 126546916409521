<div class="ng-autocomplete common">
  <ng-autocomplete [(ngModel)]="testModal" [data]="inputSelections" [searchKeyword]="searchKeyword"
    (inputCleared)="onChangeSearch($event)" (selected)='selectEvent($event)'
    (inputChanged)='onChangeSearch($event)' (inputFocused)='onFocused($event)' [itemTemplate]="itemTemplate"
    [notFoundTemplate]="notFoundTemplate">
  </ng-autocomplete>

  <ng-template #itemTemplate let-item>
    <a [innerHTML]="item?.jobTitle"></a>
  </ng-template>

  <ng-template #notFoundTemplate let-notFound>
    <div [innerHTML]="notFound"></div>
  </ng-template>
</div>

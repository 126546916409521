import { Component, AfterViewInit, EventEmitter, Output, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { GenericCallsService } from 'src/app/appService/generic-calls.service';
import { MessagingService } from 'src/app/appService/messaging.service';
import { Config } from 'src/app/common/config/config';
import { ProfileStore } from 'src/app/store/userProfileStore.store';
import { uniqBy } from "lodash";

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html'
})
export class NavigationComponent implements OnInit, AfterViewInit {
  @Output() toggleSidebar = new EventEmitter<void>();
  config = Config.AppConfig
  public scrollConfig: PerfectScrollbarConfigInterface = {};

  public showSearch = false;
  public showMore = null;
  // This is for Notifications
  notifications: any[] = [];
  displayNotifications:any[]=[];
  pagination={limit:20,offset:0}
  dataCount: any;
  notificationsToShow: any;
  constructor(
    private messagingService: MessagingService,
    public apiService: GenericCallsService,
    // private modalService: NgbModal,
    public genericService: GenericCallsService,
    public profileStore: ProfileStore,
    private router: Router
  ) { }
  title = 'push-notification';
  message;

  ngOnInit() {
    this.messagingService.requestPermission();
    setTimeout(() => {
      this.messagingService.receiveMessage()
    }, 3000);
    this.message = this.messagingService.currentMessage;
    this.openNotifications();
    console.log("data profile", this.profileStore.userData.organization);
  }
  getRole() {
    return this.profileStore.userData?.role.match('Employee') ? 'Employee' :
      this.profileStore.userData?.role.match('Employer') ? 'Employer' :
        this.profileStore.userData?.role.match('Service') ? 'Service Provider' :
          this.profileStore.userData?.role.match('Company') ? 'Company User' : 'Admin'
  }
  onLogout() {
    this.genericService.logout().subscribe(res => {
      this.profileStore.reset();
      this.messagingService.deleteMesage();
      this.router.navigate(['/']);
    }, err => {
      this.profileStore.reset();
      location.reload();
      return err;
    })
  }
  openNotifications() {
    this.notificationsToShow=[];
    this.apiService.getNotifications(this.pagination).subscribe((res: any) => {
      
      this.notifications=this.notifications.concat(res?.rows);
      if(this.notifications.length>0)
      {
        for(let i=0;i<this.notifications.length;i++)
        {
          let res=this.isContainsUrl(this.notifications[i].msgString);
          if(res)
          {
            let noti=Object.assign({},this.notifications[i]);
            noti.msgString=this.urlify(this.notifications[i].msgString);
            this.notificationsToShow.push(noti);
    
          }else{
            let noti=Object.assign({},this.notifications[i]);
            // noti.msgString=this.urlify(this.notifications[i].msgString);
            this.notificationsToShow.push(noti);
    

          }
        }
      }
      // this.notificationsToShow = uniq(this.notificationsToShow); 
      this.notificationsToShow = uniqBy(this.notificationsToShow, function (e) {
        return e.id;
      });
      this.dataCount=this.notificationsToShow.length;   
    })
    console.log('checking the notification', this.notificationsToShow);
    
  }
  loadMore(){
    // not load if length is equel to dataCount
    // if(this.dataCount> this.notificationsToShow.length){
      this.pagination={limit:10,offset:this.pagination.offset == 0 ? 20 : this.pagination.offset + 10}
    // }
    this.openNotifications()
  }
  expand(index) {
    this.showMore = index;
  }
  onReadNotifications() {
    this.pagination={limit:10+this.pagination.offset,offset:0}
    this.apiService.onReadNotifications().subscribe((res: any) => {
      this.initNotifications();
    })
  }

  initNotifications(){
    this.notifications=[];
    this.notificationsToShow=[]
    this.apiService.getNotifications(this.pagination).subscribe((res: any) => {
      
      this.notifications=res?.rows;
      for(let i=0;i<this.notifications.length;i++)
      {
        let res=this.isContainsUrl(this.notifications[i].msgString);
        if(res)
        {
          let noti=Object.assign({},this.notifications[i]);
          noti.msgString=this.urlify(this.notifications[i].msgString);
          this.notificationsToShow.push(noti);
  
        }else{
          let noti=Object.assign({},this.notifications[i]);
          // noti.msgString=this.urlify(this.notifications[i].msgString);
          this.notificationsToShow.push(noti);
  

        }
      }
    })
  }

  urlify(text) {
    var urlRegex = /(https?:\/\/[^\s]+)/g;
    return text.replace(urlRegex, function(url) {
      let value=`<a target="_blank" href="`+ `${url}`+` ">`+` ${url} `+` </a>`; 
    console.log("value",value);
      return value;     })
  }

  isContainsUrl(text)
  {
    var urlRegex = /(https?:\/\/[^\s]+)/g;

    if(text.match(urlRegex))
    {
      return true;
    }
    else{
      return false;
    }
     
  }
  goTo(data) {
    console.log(data)
    if (this.profileStore.userData.role.match('Employer') && data.type == 'Service') {
      this.router.navigate(['auth/service/post-service'])
    }
    if (this.profileStore.userData.role.match('Employer') && (data.type == 'Job' || data.type == 'Job Post')) {
      this.router.navigate(['auth/jobs/postjob'])
    }
    if (this.profileStore.userData.role.match('Service') && data.type == 'Service') {
      this.router.navigate(['auth/service/service-view', { id: parseInt(data['serviceId']), mappingId: 'serviceId' }])
    }
    if (this.profileStore.userData.role.match('Employee') && (data.type == 'Job' || data.type == 'Job Post')) {
      this.router.navigate(['auth/jobs/job-view/', parseInt(data['jobId'])])
    }
    if (this.profileStore.userData.role.match('Employee') && data.type == "Interview") {
      this.router.navigate(['auth/interviews/u-interviews'])
    }
    if (this.profileStore.userData.role.match('Employer') && data.type == "Interview") {
      this.router.navigate(['auth/jobs/e-interviews'])
    }
    if (this.profileStore.userData.role.match('Employer') && data.type == "Message") {
      this.router.navigate(['auth/messages/custom-question-message'])
    }
    if (this.profileStore.userData.role.match('Service') && data.type == "Interview") {
      this.router.navigate(['auth/interviews/sp-interviews'])
    }
    if (!this.profileStore.userData.role.match('Admin') && data.type == 'Account') {
      this.router.navigate(['auth/resume'])
    }
    if (this.profileStore.userData.role.match('Admin') && data.type == 'Job') {
      this.router.navigate(['auth/manage/m-jobs'])
    }
    if (this.profileStore.userData.role.match('Admin') && data.type == 'Service') {
      this.router.navigate(['auth/manage/m-services'])
    }
    if (!this.profileStore.userData.role.match('Admin') && data.type == 'Professional Development') {
      this.router.navigate(['auth/host/h-dev'])
    }
    if (data.type.match('Training') && !this.profileStore.userData.role.match('Admin')) {
      this.router.navigate(['auth/upcoming/u-dev'])
    }
    if (data.type.match('Training') && this.profileStore.userData.role.match('Admin')) {
      this.router.navigate(['auth/manage/m-d-trainings'])
    }
    if (data.type == 'Professional Development' && this.profileStore.userData.role.match('Admin')) {
      this.router.navigate(['auth/manage/DT-requests'])
    }
    if (data.type == 'Account' && data.msgString.match('employer') && this.profileStore.userData.role.match('Admin')) {
      this.router.navigate(['auth/manage/m-employer'])
    }
    if (data.type == 'Account' && data.msgString.match('service') && this.profileStore.userData.role.match('Admin')) {
      this.router.navigate(['auth/manage/m-s-provider'])
    }
    if (data.type == 'Account' && data.msgString.match('employee') && this.profileStore.userData.role.match('Admin')) {
      this.router.navigate(['auth/manage/m-employee'])
    }
    if (this.profileStore.userData.role.match('Employer') && data.type == "Message") {
      this.router.navigate(['auth/messages/custom-question-message'])
    }
    if (this.profileStore.userData.role.match('Service') && data.type == "Message") {
      this.router.navigate(['auth/service/messages'])
    }
    if (this.profileStore.userData.role.match('Employer') && data.type == "Interest") {
      this.router.navigate(['auth/profile/updates'])
    }
    if ((this.profileStore.userData.role.match('Employer') || this.profileStore.userData.role.match('Employee')) && data.type == "View") {
      this.router.navigate(['auth/profile/views'])
    }
    if (this.profileStore.userData.role.match('Employee') && data.type == "Message") {
      this.router.navigate(['auth/messages/custom-videos'])
    }
    if ((this.profileStore.userData.role.match('Employer') || this.profileStore.userData.role.match('Employee')) && data.type == "Video") {
      this.router.navigate(['auth/media/media-library'])
    }
    if (this.profileStore.userData.role.match('Service') && data.type == 'Video') {
      this.router.navigate(['auth/media/media-library'])
    }
    if (this.profileStore.userData.role.match('Service') && data.type == 'blog creation') {
      this.router.navigate(['auth/blog/connection-center'])
    }
    if (this.profileStore.userData.role.match('Service') && data.type == 'Service Post') {
      this.router.navigate(['auth/service/services'])
    }
  }
  isNewNotification() {
    return this.notifications && this.notifications.some(x => x.read == 0)
  }


  ngAfterViewInit() { }
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/internal/operators/map';
import { requests } from 'src/app/common/config/requests';
// import { CountriesData } from 'src/app/common/data/countries';

@Injectable({providedIn: 'root'})
export class MasterDataService {
    constructor(public httpClient: HttpClient) { }
    fillData(input) {
        return this.httpClient.post(requests.fillData, input).pipe(map((res: any) => {
          return res.result.data;
        }))
      }
    
}
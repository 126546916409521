import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { languages } from 'countries-list';
import { MasterDataService } from '../service/masterDataFetcher';

@Component({
  selector: 'app-dynamic-multiselect-dropdown',
  templateUrl: './dynamic-multiselect-dropdown.component.html',
  styleUrls: ['./dynamic-multiselect-dropdown.component.css']
})

export class DynamicMultiselectDropdownComponent implements OnInit {
  @Input() field: any;
  @Input() formField: FormGroup;
  @Input() required: boolean = false;
  @Input() validate: boolean = false;
  @Output() modelChange: EventEmitter<any> = new EventEmitter<any>();

  multiSelectConfig: any = {
    singleSelection: false,
    // maxHeight: 160,
    idField: 'id',

    textField: 'name',
    placeholder: '',
    enableCheckAll: false,
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    allowSearchFilter: false
  }

  constructor(public apiService: MasterDataService) {
    setTimeout(() => {
      this.multiSelectConfig = {
        singleSelection: false,
        maxHeight: 160,
        idField: this.field && this.field.properties.propertyToBind ? this.field.properties.propertyToBind : 'id',
        textField: this.field && this.field.properties.propertyToMap ? this.field.properties.propertyToMap : 'name',
        placeholder: this.field && this.field.label ? this.field.label : '',
        enableCheckAll: false,
        selectAllText: 'Select All',
        unSelectAllText: 'UnSelect All',
        allowSearchFilter: true,
      }
    }, 500);
  }

  ngOnInit(): void {
    if (this.field && this.field.properties && !this.field.properties.frontendMapping) {
      if (!this.field.properties['dataUpdated']) {
        this.apiService.fillData(this.field).subscribe(res => {
          this.field = res;
          this.field.properties['dataUpdated'] = true;
          this.modelChange.emit(this.field);
        })
      }
    } else if (this.field && this.field.properties && this.field.properties.frontendMapping) {
      let myLanguages = [];
      Object.entries(languages).forEach(
        ([key, value]) => {
          value['id'] = Math.random();
          myLanguages.push(value)
        });
      this.field.properties.data = [...myLanguages];
    }
  }

  onItemSelect(selected) {
    this.field.value = [selected]
    this.modelChange.emit(this.field);
  }

  onItemDeSelect(deselect) {
    this.field.value = [deselect]
    this.modelChange.emit(this.field);
  }

  get formcontrols() {
    return this.formField && this.formField.controls;
  }

  isRequired(fieldName) {
    return this.formcontrols[fieldName] && this.formcontrols[fieldName].errors && this.formcontrols[fieldName].errors.required;
  }

  isError(fieldName) {
    return this.formcontrols[fieldName] && this.formcontrols[fieldName] && this.formcontrols[fieldName].errors
  }
  setClass() {
    this.setStatus();
    if (this.field?.value && this.field?.value.length > 0) { return 'validField' }
    else { return 'invalidField' }
  }
  setStatus() {
    (this.field?.value && this.field?.value.length > 0) ? this.required = true : this.required = false;
  }

}


import { Component, Input, Output, EventEmitter, OnInit, OnChanges } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { FormBuilder } from '@angular/forms';
import { GlobalDialogModel } from './globalDialogModel';
declare var $: any;
export interface DialogData {
  Confirm: boolean
}
const nonWhitespaceRegExp: RegExp = new RegExp("\\S");
export function noWhitespaceValidator(control: FormControl) {
  const isSpace = (control.value || '').match(/\s/g);
  return isSpace ? { 'whitespace': true } : null;
}
@Component({
  selector: 'global-dialog',
  templateUrl: './global-dialog-component.html',
  styleUrls: ['./global-dialog-component.scss'],
  // changeDetection:ChangeDetectionStrategy.OnPush

})
export class GlobalDialogComponent implements OnInit, OnChanges {
  @Input() dialogData: GlobalDialogModel;
  @Output() dialogResponse: EventEmitter<any> = new EventEmitter<any>();
  @Output() dialogSecondResponse: EventEmitter<any> = new EventEmitter<any>();
  @Output() onClose: EventEmitter<boolean> = new EventEmitter<boolean>();
  globalForm: FormGroup;
  submitted: boolean;
  constructor(public fb: FormBuilder) {
    this.dialogData = new GlobalDialogModel();

  }

  ngOnInit() {
    this.submitted = false;
    // $('#deleteUser').modal('show');
    // $('#'+this.dialogData.id).modal({ backdrop: 'static', keyboard: false })
    this.globalForm = this.fb.group({
      inputValue: ['', [Validators.required, Validators.minLength(3), Validators.pattern(nonWhitespaceRegExp)]],
      // this.globalForm = new FormGroup({
      //   inputValue: new FormControl(''),

    })

  }
  initForm() {

    this.globalForm = this.fb.group({
      inputValue: ['', [Validators.required, Validators.minLength(3), Validators.pattern(nonWhitespaceRegExp)]],
      // this.globalForm = new FormGroup({
      //   inputValue: new FormControl(''),

    })
    this.submitted = false;
  }

  public noWhitespaceValidator(control: FormControl) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { 'whitespace': true };
  }

  isRequired(fieldName) {
    return this.submitted && this.dialogData.inputHeading && this.globalForm && !this.globalForm.value[fieldName]
  }
  ngOnChanges() {
    this.dialogData
    // this.dialogData.dialogType = this.data.dialogType;
    // this.dialogData.heading = this.data.heading;
    // this.dialogData.description = this.data.description;
    // this.dialogData.inputHeading = this.data.inputHeading;
    // this.dialogData.actionButtonText = this.data.actionButtonText;
    // this.dialogData.secondActionButtonText = (this.data.secondActionButtonText ? this.data.secondActionButtonText : '');
    // this.dialogData.detailsArray = this.data.detailsArray;
    // this.dialogData.selectedData = this.data.selectedData
  }
  save() {
    if (this.dialogData.inputHeading) {
      if (this.globalForm.valid && this.globalForm.controls.inputValue.value) {
        this.dialogResponse.emit(this.globalForm.value);
        this.close()
        this.initForm()
      }
    } else {
      // this.dialogResponse.emit(true);
      this.dialogResponse.emit(this.dialogData.selectedData ? this.dialogData.selectedData : true);
      this.close()
      this.initForm()
    }

  }

  saveSecondAction() {
    if (this.dialogData.inputHeading) {
      this.submitted = true;

      if (this.globalForm.valid && this.globalForm.controls.inputValue.value.trim()) {
        this.dialogResponse.emit({ data: this.globalForm.value, inputValue: this.globalForm.value });
        this.close()
        this.initForm();
      }
    } else {
      this.dialogSecondResponse.emit(this.dialogData.selectedData ? this.dialogData.selectedData : true);
      this.close()
      this.initForm()
    }


  }

  close() {
    // this.dialogData = new GlobalDialogModel();
    this.submitted = false;
    this.onClose.emit(false);
    this.initForm()
  }
}

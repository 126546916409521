import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { CommonStore } from '../store/common.store';
import { ProfileStore } from '../store/userProfileStore.store';
declare var $: any
@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
    constructor(
        public router: Router,
        public profileStore: ProfileStore,
        private commonStore: CommonStore) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {


console.log("akjdlkajsdalkjdljsaljdlkajdaslj");

        var str = state.url;
        var n = str.lastIndexOf('/');
        var result = str.substring(n + 1);
        var k = result.indexOf(';');
        var result2 = result.substring(k + 1);

        var url = state.url.split('/');
        var typeOfResume = url[url.length - 1]; // 2
        var id = url[url.length - 2];

        result2 = result2.replace("Service_Provider", "Service Provider");
        console.log("auth routing");

        if (this.profileStore.userData && this.profileStore.userData.id && this.profileStore.userToken) {

            if (state.url.match('auth/dashboard') && this.profileStore.userData.role == 'Admin') {
                this.router.navigate(['auth/resume']);
                return true;
            }
            // debugger
            return true;
        } else {

            if (state.url.match('auth/jobs/job-view')) {
                this.router.navigateByUrl('search/job-view/' + result);
                return false;
            }
            else if (state.url.match('auth/service/service-view') && (this.profileStore.userData.role.match('Service_Provider') || (this.profileStore.userData.role.match('Employer') || this.profileStore.userData.role == 'Admin'))) {
                this.router.navigateByUrl('search/service-view/' + result);
                return false;
            }
            else if (state.url.match('auth/upcoming/u-view') && (this.profileStore.userData.role.match('Service_Provider') || (this.profileStore.userData.role.match('Employer') || this.profileStore.userData.role == 'Admin'))) {
                this.router.navigateByUrl('search/training-view/' + result);
                return false;
            }
            else if (state.url.match('auth/review/employee-resume')) {
                let m = result.lastIndexOf('=');
                let role = result.substring(m + 1);
                console.log("role", role);

                if (role == "Employee") {
                    this.router.navigateByUrl('search/r-employee/' + id + '/' + typeOfResume);
                }
                else if (role == "Employer") {
                    this.router.navigateByUrl('search/r-employer/' + id + '/' + typeOfResume);
                }
                else if (role == "Service_Provider") {
                    this.router.navigateByUrl('search/r-serviceProvider/' + id + '/' + typeOfResume);

                } else {
                    console.log("role=else", role);

                }
            }
            else if (state.url.match('blog/connection-center')) {
                this.commonStore.setRedirectUrl(state.url);
                this.router.navigateByUrl('login');
                //  return false
            }
            // else if (state.url.match('/unsubscribe'))
            // {
            //     localStorage.setItem('fromUnsubscribe','true');
            //     this.router.navigate(['/login']);
            //     return false;
            // }
            else {
                console.log("else blok");

                this.router.navigate(['/'])
                return false;
            }
        }
    }
}
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Observable } from 'rxjs';

@Component({
  selector: 'intellisense-input',
  templateUrl: './intellisense-input.component.html',
  styleUrls: ['./intellisense-input.component.scss']
})
export class IntellisenseInputComponent implements OnInit {

  testModal:any;

  @Input() $clear: Observable<any>;
  @Input() placeholder: string = 'Select';
  @Input() inputSelections: Array<any> = [];
  @Input() searchKeyword: string = '';
  @Output() onSelect: EventEmitter<any> = new EventEmitter<any>();
  @Output() onChangeInput: EventEmitter<any> = new EventEmitter<any>();
  constructor() { }

  ngOnInit(): void {
    if(this.$clear){
      this.$clear.subscribe(res=>{
        this.testModal=''
      })
    }
  }
  selectEvent(search) {
    this.onSelect.emit(search);
  }

  onChangeSearch(value) {
    if (value && value.length > 25) {
      this.testModal = "";
      value = ""
    }
    this.onChangeInput.emit(value ? value.trim() : '');
  }

  onFocused(event) {
    event.stopPropagation();
  }

}

import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonModule } from '@angular/common';
import { Inject, NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RouterModule } from '@angular/router';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { FullComponent } from './layouts/full/full.component';
import { BlankComponent } from './layouts/blank/blank.component';

import { NavigationComponent } from './layouts/header-navigation/navigation.component';
import { SidebarComponent } from './layouts/sidebar/sidebar.component';
import { BreadcrumbComponent } from './layouts/breadcrumb/breadcrumb.component';

import { Approutes } from './app-routing.module';
import { AppComponent } from './app.component';
import { SpinnerComponent } from './layouts/spinner.component';

import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
// import { AuthInterceptor } from './Interceptor/authInterceptor';
import { AuthInterceptor } from './Interceptor/authInterceptor';
import { CommonStore } from './store/common.store';
import { SharedModule } from './shared/shared.module';
import { ProfileStore } from './store/userProfileStore.store';
import { S3FilesStore } from './store/signedFiles.store';
import { GenericCallsService } from './appService/generic-calls.service';
import { ServerDownComponent } from './layouts/server-down/server-down.component';
import { MasterDataStore } from './store/masterData.store';
import { FormGeneratorService } from './appService/form-generator.service';
import { ClipboardModule } from 'ngx-clipboard';
import { AngularFireMessagingModule } from '@angular/fire/messaging';
// import { AngularFireDatabaseModule } from '@angular/fire/database';
// import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireModule } from '@angular/fire';
import { MessagingService } from './appService/messaging.service';
import { environment } from 'src/environments/environment';
import { AsyncPipe } from '../../node_modules/@angular/common';
import { AngularFireMessaging } from '@angular/fire/messaging/messaging';
import { NotFoundComponent } from './layouts/not-found/not-found.component';
import { ReplaceUnderscorePipe } from './common/pipes/replace-underscore.pipe';
// import { ServiceWorkerModule } from '@angular/service-worker';
import {
  Injectable,
  Injector,
  InjectionToken,
  ErrorHandler
} from '@angular/core';
import * as Rollbar from 'rollbar';
const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
  wheelSpeed: 2,
  wheelPropagation: true,
  minScrollbarLength: 20
};
const rollbarConfig = {
  accessToken: 'd4c8cfa352e34dfebabda5a41501729f',
  captureUncaught: true,
  captureUnhandledRejections: true,
};
@Injectable()
export class RollbarErrorHandler implements ErrorHandler {
  constructor(@Inject(RollbarService) private rollbar: Rollbar) {}

  handleError(err:any) : void {
    this.rollbar.error(err.originalError || err);
  }
}

export function rollbarFactory() {
    return new Rollbar(rollbarConfig);
}

export const RollbarService = new InjectionToken<Rollbar>('rollbar');

@NgModule({
  declarations: [
    AppComponent,
    SpinnerComponent,
    FullComponent,
    BlankComponent,
    NavigationComponent,
    BreadcrumbComponent,
    SidebarComponent,
    ServerDownComponent,
    NotFoundComponent,
    ReplaceUnderscorePipe,
  ],
  imports: [
    CommonModule,
    BrowserModule.withServerTransition({ appId: 'edu-opening' }),
    BrowserAnimationsModule,
    SharedModule,
    ClipboardModule,
    // FormsModule,
    HttpClientModule,
    NgbModule,
    RouterModule.forRoot(Approutes,{

      scrollPositionRestoration: 'enabled',
      anchorScrolling: 'enabled',
      useHash: false,
      enableTracing: false
    }),
    PerfectScrollbarModule,
    // AngularFireDatabaseModule,
    // AngularFireAuthModule,
    AngularFireMessagingModule,
    AngularFireModule.initializeApp(environment.firebase),
    // ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production })

  ],
  providers: [
    { provide: ErrorHandler, useClass: RollbarErrorHandler },
    { provide: RollbarService, useFactory: rollbarFactory },
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    // { provide: HTTP_INTERCEPTORS, useClass: IronHttpInterceptor, multi: true }
    MasterDataStore,
    CommonStore,
    ProfileStore,
    S3FilesStore,
    GenericCallsService,
    FormGeneratorService,
    // firebase disabled due to certificates on testing server 
    MessagingService,
    AsyncPipe,
    AngularFireMessaging
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

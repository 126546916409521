import { Injectable } from '@angular/core';
import { observable, action, computed, autorun } from 'mobx';
import * as moment from 'moment';
import { cloneDeep } from "lodash";
@Injectable({ providedIn: 'root' })
export class S3FilesStore {
    @observable files: Array<{ key: string, url: string, updatedTime: Date,id:string }> = [];
    constructor() {
        if (window.localStorage['files']) {
            let user:Array<any> = JSON.parse(localStorage["files"]);
            this.files = cloneDeep(user);
        }
        autorun(() => {
            if (this.files) {
                window.localStorage.setItem('files', JSON.stringify(this.files))
            }
            if (window.localStorage['files']) {
                let user:Array<any> = JSON.parse(localStorage["files"]);
                this.files = cloneDeep(user);
            }
        })
     }

    @action getFileFromStore(key) {
        if (this.files && this.files.length > 0) {
            let fileExist = this.files.find(x => x.key == key);
            if (fileExist) {
                var now = new Date();
                var then = fileExist.updatedTime;
                var diff = moment.duration(moment(now).diff(moment(then)));
                var days = parseInt(diff.asDays().toString());
                var hours = parseInt(diff.asHours().toString());
                hours = hours - days * 24;
                return hours > 150 ? null : fileExist.url;
               
            }
        }
        else {
            return null;
        }
    }

    @action insertFile(url, key, id?) {
        if (url && url != {}) {
            let file = { url: url, key: key, updatedTime: new Date() ,id:id ? id:Math.random()};
            let index = this.files.findIndex(x => x.key == key);
            if (index > -1) {
                this.files[index] = Object.assign({}, file)
            } else {
                this.files.push(Object.assign({}, file));
            }
            this.files = [...this.files];
            if (this.files) {
                window.localStorage.setItem('files', JSON.stringify(this.files))
            }
        }
    }

}
<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title">Send Email To Employees</h4>
  <button type="button" class="close" aria-label="Close" (click)="close()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body modal-body-scrollable">
  <div class="d-flex justify-content-between align-items-center mb-3">
    <div></div>
    <button type="button" class="btn btn-primary" (click)="sendEmails()">Send</button>
  </div>
  <table class="table table-striped border mt-4 email-table">
    <thead>
      <tr>
        <th><input type="checkbox" (change)="toggleSelectAll($event)"></th>
        <th>Name</th>
        <th>Email</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let user of allUsers">
        <td><input type="checkbox" (change)="toggleSelection(user?.id)" [checked]="selectedUserIds.has(user?.id)"></td>
        <td>{{user.firstName + ' ' + user.lastName}}</td>
        <td>{{user.User.email}}</td>
      </tr>
    </tbody>
  </table>
</div>

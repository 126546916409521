export const environment = {  
  // edu opening
  url:'https://backend.eduopenings.com',
  shareLink:'https://eduopenings.com',
  // staging
  // url:'https://stagingapi.eduopenings.com',
  // shareLink:'https://staging.eduopenings.com',
  // url:'http://3.209.204.33:5000',
  // testing
  // url:'https://testingapi.eduopenings.com',
  // shareLink:'https://testing.eduopenings.com',
  production: true,
  SeoEnable:true,
  firebase: {
    apiKey: "AIzaSyAc1lGCea-9i35tGeQ6_altBMJHlA5yTLw",
    authDomain: "eduopenings-b38c8.firebaseapp.com",
    projectId: "eduopenings-b38c8",
    storageBucket: "eduopenings-b38c8.appspot.com",
    messagingSenderId: "796901842420",
    appId: "1:796901842420:web:d458ef7d314d11093fada1",
    measurementId: "G-2VELQNBB3E"
  }
};

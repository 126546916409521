import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'tConvert'
})
export class TConvertPipe implements PipeTransform {

  transform(time:any): any {
      // Check correct time format and split into components
      if(time && time!=""){
        time = time.toString ().match (/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];
      
        if (time.length > 1) { // If time format correct
          time = time.slice (1);  // Remove full string match value
          time[5] = +time[0] < 12 ? ' AM' : ' PM'; // Set AM/PM
          time[0] = +time[0] % 12 || 12; // Adjust hours
        }
      }
      else{
        time=['-','-',':','-','-'];
      }
      return time && time.join (''); // return adjusted time or original string
    
  }

}

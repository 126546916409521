import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { take } from 'rxjs/operators';
import { requests } from 'src/app/common/config/requests';

@Component({
  selector: 'app-email-sending-popup',
  templateUrl: './email-sending-popup.component.html',
  styleUrls: ['./email-sending-popup.component.css']
})
export class EmailSendingPopupComponent implements OnInit {
  @Output() onClose: EventEmitter<any> = new EventEmitter<any>();
  selectedUserIds: Set<number> = new Set<number>();

  @Input() jobId: string; 
  allUsers = [];
  constructor(private http: HttpClient) { }
  ngOnInit(): void {
    this.http.get(requests.getEmailSendingUsers).pipe(take(1)).subscribe((data:any)=>{
      console.log(data);
      this.allUsers = data?.result?.data;
    })
  }
  toggleSelectAll(event: Event) {
    const checked = (event.target as HTMLInputElement).checked;
    if (checked) {
      this.allUsers.forEach(user => this.selectedUserIds.add(user.id));
    } else {
      this.selectedUserIds.clear();
    }
  }
  toggleSelection(userId: number) {
    if (this.selectedUserIds.has(userId)) {
      this.selectedUserIds.delete(userId);
    } else {
      this.selectedUserIds.add(userId);
    }
    this.updateSelectAllState();
  }
  isAllSelected(): boolean {
    return this.allUsers.length > 0 && this.selectedUserIds.size === this.allUsers.length;
  }
  updateSelectAllState() {
    const selectAllCheckbox = document.querySelector('thead input[type="checkbox"]') as HTMLInputElement;
    selectAllCheckbox.checked = this.isAllSelected();
    selectAllCheckbox.indeterminate = this.selectedUserIds.size > 0 && !this.isAllSelected();
  }

  sendEmails() {
    const selectedIds = Array.from(this.selectedUserIds);
    console.log('Selected User IDs:', selectedIds);
    this.http.post(requests.sendEmailToUsers,{
      "params": {
        "employeeIds": selectedIds,
        "jobId": parseInt(this.jobId)
    }
    }).pipe(take(1)).subscribe(data=>{
      this.close();
    })
  }
  close(){
    this.onClose.emit('close');
  }
}

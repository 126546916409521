import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { of } from 'rxjs';
import { forkJoin } from 'rxjs/internal/observable/forkJoin';
// import { of } from 'rxjs/internal/observable/of';
import { throwError } from 'rxjs/internal/observable/throwError';
import { map, mergeMap } from 'rxjs/operators';
import { requests } from '../common/config/requests';
import { CommonStore } from '../store/common.store';
// import { MasterDataService } from '../shared/service/masterDataFetcher';
import { MasterDataStore } from '../store/masterData.store';
import { ProfileStore } from '../store/userProfileStore.store';

@Injectable({
  providedIn: 'root'
})
export class GenericCallsService {

  constructor(
    public httpClient: HttpClient,
    public masterStore: MasterDataStore,
    public commonStore: CommonStore,
    public profileStore: ProfileStore) { }

  getTableData(params, pagination, filter?, sort?) {
    if (this.profileStore.haveAccess(params.tableName)) {
      let pg = { offset: pagination.offset == 0 ? pagination.offset : pagination.offset * pagination.limit, limit: pagination.limit }
      return this.httpClient.post(requests.tableView, { pagination: pg, params: params, filter: filter, sort: sort }).pipe(map((res: any) => {
        return res.result.data
      }))
    } else {
      // this.commonStore.notifier({ message: params.tableName + ' Is Unauthorized View', action: 'error' })
      return throwError({ message: params.tableName + ' Is Unauthorized View' })
    }
  }
  getExportData(body){
    return this.httpClient.post(requests.exportApi, body).pipe(map((res: any) => {
      return res.results?.rows
    }))
  }

  getJobList(search){
    return this.httpClient.post(requests.myJobsIntelliscense, search).pipe(map((res: any) => {
      return res?.results && res.results.length && res.results;
    }))
  }
  getLandingTableData(params, pagination, filter?, sort?) {
    // if (this.profileStore.haveAccess(params.tableName)) {
    let pg = { offset: pagination.offset == 0 ? pagination.offset : pagination.offset * pagination.limit, limit: pagination.limit }
    return this.httpClient.post(requests.tableViewLanding, { pagination: pg, params: params, filter: filter, sort: sort }).pipe(map((res: any) => {
      return res.result.data
    }))
    // } else {
    //   this.commonStore.notifier({ message: params.tableName + ' Is Unauthorized View', action: 'error' })
    //   return throwError({ message: params.tableName + ' Is Unauthorized View' })
    // }
  }
  getLandingForm(body) {
    // if (this.profileStore.haveAccess(body.params.tableName)) {
    return this.httpClient.post(requests.getFormLanding, body).pipe(map((res: any) => {
      return res.result.fields
    }))
    // } else {
    //   this.commonStore.notifier({ message: body.params.tableName + ' Is Unauthorized View', action: 'error' })
    //   return throwError({ message: body.params.tableName + ' Is Unauthorized View' })
    // }
  }
  getQuestions() {
    return this.httpClient.get(requests.getQuestions).pipe(map((res: any) => {
      return res?.results && res.results.length && res.results;
    }))
  }
  getlandingFilters(body) {
    // if (this.profileStore.haveAccess(body.params.tableName)) {
    return this.httpClient.post(requests.getFilterLanding, body).pipe(map((res: any) => {
      return res.result.filter
    }))
    // } else {
    //   return throwError({ message: body.params.tableName + ' Is Unauthorized View' })
    // }
  }

  getFilters(body) {
    if (this.profileStore.haveAccess(body.params.tableName)) {
      return this.httpClient.post(requests.getFilter, body).pipe(map((res: any) => {
        return res.result.filter
      }))
    } else {
      return throwError({ message: body.params.tableName + ' Is Unauthorized View' })
    }
  }

  getForm(body) {
    if (this.profileStore.haveAccess(body.params.tableName)) {
      return this.httpClient.post(requests.getForm, body).pipe(map((res: any) => {
        return res.result
      }))
    } else {
      this.commonStore.notifier({ message: body.params.tableName + ' Is Unauthorized View', action: 'error' })
      return throwError({ message: body.params.tableName + ' Is Unauthorized View' })
    }
  }
  convertToServerModal(filterArrays) {
    let countryCode = '';
    let stateCode = '';
    filterArrays.forEach(field => {
      if (field.inputType == "dateRange" && field.value.startDate && field.value.endDate) {
        field.value.startDate = moment(field.value.startDate).format('yyyy-MM-DD');
        field.value.endDate = moment(field.value.endDate).format('yyyy-MM-DD');
      }
      else if (field.inputType == 'dropdown' && field.properties && field.properties.propertyToBind == 'id') {
        field.value = parseInt(field.value);
      }
      else if (field.properties && field.properties.currentUserId) {
        field.value = this.profileStore.userData.id;
      }
      else if (field.properties && field.properties.currentEmployerId) {
        field.value = this.profileStore.userData.employerId || filterArrays.find(x=>x.colName=='employerId').value;;
      }
      else if (field.colName == 'country') {
        countryCode = field.value;
        if (countryCode) {

          field.value = this.masterStore.getCountries.find(y => y.isoCode == field.value || y.name == field.value)?.name;
        }
      }
      else if (field.colName == 'state') {
        stateCode = field.value;
        if (stateCode) {
          field.value = this.masterStore.getStates(countryCode).find(y => y.code == field.value)?.name;
        }
      }
      // else if(field.inputType == 'time'){
      //   field.value =field.value ?  this.tConvert(field.value):null;
      // }

    })
  }
  tConvert (time) {
    
    // Check correct time format and split into components
    time = time.toString ().match (/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];
  
    if (time.length > 1) { // If time format correct
      time = time.slice (1);  // Remove full string match value
      time[5] = +time[0] < 12 ? ' AM' : ' PM'; // Set AM/PM
      time[0] = +time[0] % 12 || 12; // Adjust hours
    }
    return time.join (''); // return adjusted time or original string
  }
  getSettings(body){
    return this.httpClient.post(requests.getSettings,body).pipe(map((res:any)=>{
        return res?.results && res.results.length && res.results[0];
    }))
  }
  saveData(body) {
    if (body.params.fields) {
      this.convertToServerModal(body.params.fields)
    }
    try{

      if (this.profileStore.haveAccess(body.params.tableName)) {
        // files uploading fork join
        let filesToUpload=body.params.fields.filter(x=>x.inputType=='file' && x.value && isNaN(x.value));
        return this.uploadOnMediaLibrary(filesToUpload).pipe(mergeMap((res:any)=>{
          // replace fiel ids here 
          if(res){
            body.params.fields.map(x=>{
              if(x.inputType=='file' && x.value){
                // console.log("file id match result", res.find(y=>y.result.key.includes(x.value.name)).result.fileId)
                x.value=res.find(y=>y.result.key.includes(x.value.name)).result.fileId;
              }
            })
          }
          return this.httpClient.post(requests.createEdit, body);
        }),map((res:any)=>{
          this.commonStore.notifier({ message: res.message, action: 'success' });
          return res
        }, err => {
          throw this.handleError(err)
        }))
        // return this.httpClient.post(requests.createEdit, body).pipe(map((res: any) => {
        //   this.commonStore.notifier({ message: res.message, action: 'success' })
        //   return res
        // }, err => {
        //   throw this.handleError(err)
        // }))
      } else {
        this.commonStore.notifier({ message: body.params.tableName + ' Is Unauthorized View', action: 'error' })
        return throwError({ message: body.params.tableName + ' Is Unauthorized View' })
      }

    }catch(e){

      console.log(e);
      throwError(e);
      
    }
  }
  uploadOnMediaLibrary(files: Array<any>) {
    let apiCall: Array<any> = [];
    if(files && files.length>0){
      files.forEach(file => {
        let formData: FormData = Object.assign(new FormData());
        
        formData.append("file", file.value);
        formData.append("title", 'docfile');
        formData.append("date", new Date().toLocaleDateString());
        apiCall.push(this.httpClient.post(requests.uploadFile, formData))
      });
      return forkJoin(apiCall).pipe(
        map(
          results => {
            return results;
          },
          err => {}
        )
      );
    }else{
      return of(null);
    }
  }
  shareItem(body, showNotify = true) {
    // if (this.profileStore.haveAccess(body.params.tableName)) {
      return this.httpClient.post(requests.shareJob, body).pipe(map((res: any) => {
        if (showNotify) {
          // this.commonStore.notifier({ message: res.message, action: 'success' })
        }
        return res
      }, err => {
        throw this.handleError(err)
      }))
    // } else {
    //   this.commonStore.notifier({ message: body.params.tableName + ' Is Unauthorized View', action: 'error' })
    //   return throwError({ message: body.params.tableName + ' Is Unauthorized View', action: 'error' })
    // // }
  }
  actionData(body, showNotify = true) {
    if (this.profileStore.haveAccess(body.params.tableName)) {
      return this.httpClient.post(requests.action, body).pipe(map((res: any) => {
        if (showNotify) {
          this.commonStore.notifier({ message: res.message, action: 'success' })
        }
        return res
      }, err => {
        throw this.handleError(err)
      }))
    } else {
      this.commonStore.notifier({ message: body.params.tableName + ' Is Unauthorized View', action: 'error' })
      return throwError({ message: body.params.tableName + ' Is Unauthorized View', action: 'error' })
    }
  }

  unauthorizedActionData(body, showNotify = true) {
    // if (this.profileStore.haveAccess(body.params.tableName)) {
      
      let action=requests.unauthorizedAction;
      return this.httpClient.post(action, body).pipe(map((res: any) => {
        if (showNotify) {
          this.commonStore.notifier({ message: res.message, action: 'success' })
        }
        return res
      }, err => {
        throw this.handleError(err)
      }))
    // } else {
    //   this.commonStore.notifier({ message: body.params.tableName + ' Is Unauthorized View', action: 'error' })
    //   return throwError({ message: body.params.tableName + ' Is Unauthorized View', action: 'error' })
    // }
  }


  deleteRecord(body) {
    if (this.profileStore.haveAccess(body.params.tableName)) {
      return this.httpClient.post(requests.delete, body).pipe(map((res: any) => {
        this.commonStore.notifier({ message: res.message, action: 'success' })
        return res.result
      }, err => {
        throw this.handleError(err)
      }))
    } else {
      return throwError({ message: body.params.tableName + ' Is Unauthorized View' })
    }
  }

  getNotifications(pagination?:{limit:number,offset:number}) {
    let body = {
      "params": {
        "tableName": "Notifications",
        "id": this.profileStore.userData.userId,
      },
      "pagination":pagination ? pagination:{limit:20, offset:0}
    }
    return this.httpClient.post(requests.getNotifications, body).pipe(map((res: any) => {
      return res.results;
    }, err => {
      return err
    }))
  }
  onReadNotifications() {
    let body = {
      "params": {
        "tableName": "Notifications",
        "id": this.profileStore.userData.userId
      }
    }
    return this.httpClient.post(requests.readNotifications, body).pipe(map((res: any) => {
      return res.results;
    }, err => {
      return err
    }))
  }

  
  getSignedUrl(key) {
    return this.httpClient.post(requests.s3FileProxy, { fileKey: key }).pipe(map(res => {
      return res
    }, err => {
      return err
    }))
  }
  
  logout() {
    return this.httpClient.post(requests.logout, {}).pipe(map((res: any) => {
      return res.result
    }, err => {
      throw this.handleError(err)
    }))
  }
  changePassword(data) {
    return this.httpClient.post(requests.changePassword, data).pipe(map((res: any) => {
      this.commonStore.notifier({ message: res.message, action: 'success' })
      return res.result
    }, err => {
      this.commonStore.notifier({ message: err.error.message || err.message, action: 'error' })
      throw this.handleError(err)
    }))
  }
  
  handleError(err) {
    // this.commonStore.notifier({ message: res.message || err.error.message || 'Error Occured' })
    return err
  }

  getDashboardData(body) {
    if (this.profileStore.haveAccess(body.tableName)) {
      return this.httpClient.post(requests.dashboard, { params: { tableName: body.tableName } }).pipe(map((res: any) => {
        // this.commonStore.notifier({ message: res.message, action: 'success' })
        return res
      }, err => {
        throw this.handleError(err)
      }))
    } else {
      this.commonStore.notifier({ message: body.tableName + ' Is Unauthorized View', action: 'error' })
      return throwError({ message: body.tableName + ' Is Unauthorized View', action: 'error' })
    }
  }

  // simple apis

  postRestApi(api, body) {
    return this.httpClient.post(requests[api], body).pipe(map((res: any) => {
      return res
    }, err => {
      throw this.handleError(err)
    }))
  }
  getRestApi(api) {
    return this.httpClient.get(requests[api]).pipe(map((res: any) => {
      return res
    }, err => {
      throw this.handleError(err)
    }))
  }

  // payment api
  onPayment(body){
    return this.httpClient.post(requests.verifyPayment,body).pipe(map((res: any) => {
      this.commonStore.notifier({ message: res.message, action: 'success' })
      return res
    }, err => {
      throw this.handleError(err)
    }))
  }
  onCustomPayment(body){
    return this.httpClient.post(requests.manualPaymentInitiated,body).pipe(map((res: any) => {
      this.commonStore.notifier({ message: res.message, action: 'success' })
      return res
    }, err => {
      throw this.handleError(err)
    }))
  }

  sendNotifications(data){
    return this.httpClient.post(requests.notifyByAdmin,data).pipe(map((res: any) => {
      this.commonStore.notifier({ message: res.message, action: 'success' })
      return res
    }, err => {
      throw this.handleError(err)
    }))
  }

  getProfilePercentenge()
  {
    return this.httpClient.get(requests.profilePercentage).pipe(map((res: any) => {
      // this.commonStore.notifier({ message: res.message, action: 'success' })
      return res
    }, err => {
      throw this.handleError(err)
    }))
  }


 
}

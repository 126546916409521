import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FileSelectorComponent } from './file-selector/file-selector.component';
@NgModule({
  declarations: [
  FileSelectorComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule.withConfig({warnOnNgModelWithFormControl: 'never'})
  ],
  exports:[
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FileSelectorComponent
  ],
  providers:[]
})
export class CommonFormModule { }

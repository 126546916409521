export var CountryInput = {
    "colName": "country",
    "label": "Country",
    "tableName": "Edu_Employee",
    "inputType": "dropdown",
    "value": null,
    "csc": true,
    "properties": {
        "string": true,
        "id": true,
        "toBeFilled": true,
        "propertyToShow": "name",
        "propertyToBind": "isoCode",
        "frontendMapping": true
    }
}

export var StateInput = {
    "colName": "state",
    "label": "State",
    "tableName": "Edu_Employee",
    "inputType": "dropdown",
    "value": null,
    "countryCode": null,
    "csc": true,
    "properties": {
        "string": true,
        "id": true,
        "toBeFilled": true,
        "propertyToShow": "name",
        "propertyToBind": "code",
        "frontendMapping": true
    }
}

export var CityInput = {
    "colName": "city",
    "label": "City",
    "tableName": "Edu_Employee",
    "inputType": "dropdown",
    "value": null,
    "countryCode": null,
    "stateCode": null,
    "csc": true,
    "properties": {
        "string": true,
        "id": true,
        "toBeFilled": true,
        "frontendMapping": true,
        "propertyToShow": "name",
        "propertyToBind": "name"
    }
}


import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { GenericCallsService } from 'src/app/appService/generic-calls.service';
import { Views } from 'src/app/common/config/views';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { ProfileStore } from 'src/app/store/userProfileStore.store';

@Component({
  selector: 'app-send-request',
  templateUrl: './send-request.component.html',
  styleUrls: ['./send-request.component.css']
})
export class SendRequestComponent implements OnInit {
  @ViewChild('popupForm', { static: false }) private popupForm;
  @Input() rightToCheck: string;
  config: any
  requestStatus: { requestSent: any; status: any; };
  closeResult: string;
  constructor(
    private apiService: GenericCallsService,
    public title: Title,
    private modalService: NgbModal,
    public profileStore: ProfileStore

  ) { }

  ngOnInit(): void {
    this.config = Views.find(x => x.title == this.title.getTitle());
    this.getStatus();
  }

  sendRequest(action) {
    let body = {
      params: {
        tableName: this.config.flag.match('Development') ? 'Request PD' : ''
      }
    }
    this.saveActionData(body);
  }
  getStatus() {
    this.apiService.postRestApi('pdStatus', {}).subscribe(res => {
      this.requestStatus = { requestSent: res.results?.status, status: res.results.master_status };
      console.log("pd status", res);
    })
  }
  onPayment(data) {
    let body = {
      card: data.card,
      cvv: data.cvv,
      expiry: data.expiry,
      plan: 'PD',
      type: 'Plan'
    }
    this.apiService.onPayment(body).subscribe(res => {
      this.profileStore.setUser(res.results);
      location.reload()
    })
  }

  openFileSelectorModel(content1: any) {
    this.modalService.open(content1, { ariaLabelledBy: 'modal-basic-title', size: 'lg' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
  private getDismissReason(reason: ModalDismissReasons): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
  onClose() {

  }
  closeBtnClick() {

    this.modalService.dismissAll()
  }
  payNow() {
    this.openFileSelectorModel(this.popupForm);

  }

  saveActionData(body) {
    this.apiService.actionData(body).subscribe(res => {
      this.getStatus();
      if (res.results && !res.results.type) {

      } else if (res.results && res.results.type && res.results.type != 'link') {
        // this.getSignedPath(res.results.path);
      }
      else if (res.results && res.results.type && res.results.type == 'link') {
        window.open(res.results.path, '_blank');
      }
    }, err => {
    });
  }

}

<div class="">
  <label class="col-form-labe" for="inputGroupFile02">{{title}}</label>
  <button title="{{title}}" class="ml-3 float-right btn btn-success waves-effect btn-circle waves-light"
    type="button" (click)="fileSelector()"> <i class="mdi mdi-upload"></i>
  </button>
  <input style="display:none" #imageSelector placeholder="Profile Picture" type="file" (change)="fileRead($event)"
    accept="{{fileTypes}}" class="form-control" id="fileToUpload">
</div>

<div class="mt-4" *ngIf="mediaObj && mediaObj.src">
  <img *ngIf="this.mediaObj.type.includes('image')" style="height: 40px;" src="{{mediaObj.src}}">{{mediaObj && mediaObj.name ? mediaObj.name:''}}
  <button title="Delete" class="ml-3 btn btn-success waves-effect btn-circle waves-light" type="button"
    (click)="removePicture()"> <i class="ti ti-trash"></i>
  </button>
</div>

<div *ngIf="error">
    <span class="text-danger">{{error}}</span>
</div>

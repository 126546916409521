export const Config={
    commonTextInputLength:25,
    patterns:{
        textOnly:"[a-zA-Z]*",
        textWithSpaces:"[a-zA-Z][a-zA-Z ]+",
        phoneNumber:"[0-9]{7,18}",
        email:"[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{1,3}$",
        posNumber:"^[0-9]*"
    },
    secretKeys:{
        // client linkedin 
        linkedinId:"78tdgv81ss2sag",
        linkedinKey:"SeD9s61tANExPBim",
        // test
        // linkedinId:"77eyx11izcc5lh",
        // linkedinKey:"2z3OPZIcSSuXiTO3",
        // client keys
        googleClientId:"412494498437-cavhasoiajs5s357vdf4ad7q5sol5jpj.apps.googleusercontent.com",
        googleApiKey:"AIzaSyDA4TkWshVwI-F9QyuHW-qMdvzJAVKpQ50"
        // demo keys
        // googleClientId:"207550224564-1qrdcn1td9277ep8fi02fgt5ovgjh1f1.apps.googleusercontent.com",
        // googleApiKey:"AIzaSyD3rdh0oHCAzjfA8aesjRkMC-TCx-pXoYQ"

    },
    AppConfig:{
        payment:false
    },
    SeoTags:{
        keywords:"Edu Openings, jobs, services, freelancers, employer, employee, video messaging, job hiring, teachers, teaching jobs,administration jobs, students, voice messaging, ineterviews, schedule interviews, voice blogs, articles",
        description:"Edu Openings is a place where Educators Find Jobs, Professional Development and Freelance Work",
        author:"EduOpenings.com"

    }
}
import { HttpClient } from '@angular/common/http';
import { Component, ViewChild, ElementRef, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { take } from 'rxjs/operators';
import { requests } from 'src/app/common/config/requests';
import { CommonStore } from 'src/app/store/common.store';

@Component({
  selector: 'app-unsubscribe-email',
  templateUrl: './unsubscribe.component.html'
})
export class Unsubscribe implements OnInit {
  @ViewChild('emailInput') emailInput: ElementRef;  // Reference to the input element
  showMessage: boolean = false;

  constructor(private http: HttpClient, private commonStore: CommonStore, private router: Router) { }

  ngOnInit() { }

  onUnsubscribe() {
    const email = this.emailInput.nativeElement.value;
    this.http.post(requests.unsubscribe, { "params": {
        "email": email
    } })
      .pipe(take(1))
      .subscribe(
        (zxc:any) => {
          this.showMessage = true;
          console.log('asda')
          setTimeout(() => {
            this.router.navigate(['/auth/resume']);
          }, 3000); // 3 seconds
        },
        (error) => {
          this.showMessage = true;
          setTimeout(() => {
            this.router.navigate(['/auth/resume']);
          }, 3000); // 3 seconds
        },
        ()=>{
            this.showMessage = true;
            setTimeout(() => {
                this.router.navigate(['/auth/resume']);
              }, 3000); // 3 seconds
            
        }
      );
  }
}

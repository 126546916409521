import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'replaceUnderscore'
})
export class ReplaceUnderscorePipe implements PipeTransform {

  transform(value: string): string {
    if((value.includes('Employee') || value.includes('Employer') || value.includes('Service_Provider')) )
    {
      // value="";
      if(value.includes('Vibranium')){
        return value='Vibranium';
      }
      else{
        if(value.includes('PD')){
          return value='Professional';
        }
        else{
          return value='Free';
        } 
      }
      
    
    }
    return value? value.replace(/_/g, " ") : value;
  }

}

    <div class="modal-header">
      <h4 class="modal-title">{{dialogData.heading}}</h4>
      <button type="button" class="close" (click)="close()" data-dismiss="modal" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body clearfix">
      <div *ngIf="dialogData.image" class="text-center m-2 p-2">
        <img class="image" src="{{dialogData.image}}">
      </div>
      <p>{{dialogData.description}}</p>
      <div *ngIf="dialogData && dialogData.detailsArray">
        <p *ngFor="let details of dialogData.detailsArray">{{details}}</p>
      </div>
      <br>
      <form *ngIf="dialogData.inputHeading" [formGroup]="globalForm" class="form-horizontal">
        <div class="form-group">
          <label for="inputValue" class="col-sm-3 control-label">{{dialogData.inputHeading}}</label>
          <div class="col-sm-9">
            <input type="text" minlength="3" [maxlength]="dialogData.inputLength"
              [placeholder]="dialogData.inputHeading || 45" formControlName="inputValue" class="form-control">
          </div>
          <span *ngIf="isRequired('inputValue')" class="text-danger">
            <small>Scenario Name is required</small>
          </span>
          <span
            *ngIf="submitted && globalForm.controls.inputValue.errors && globalForm.controls.inputValue.errors.minlength"
            class="text-danger">
            <small>{{globalForm.controls.inputValue.errors.minlength.requiredLength}} charactors required</small>
          </span>
          <span
            *ngIf="submitted && globalForm.controls.inputValue.errors && globalForm.controls.inputValue.errors.pattern"
            class="text-danger">
            <small>Please input valid title</small>
          </span>
        </div>

      </form>


    </div>
    <div class="modal-footer bg-light-tone">
      <button type="button" (click)="close()" class="btn btn-light" data-dismiss="modal">Cancel</button>
      <button type="button" *ngIf="dialogData.secondActionButtonText" (click)="saveSecondAction()"
        class="btn btn-info">{{dialogData.secondActionButtonText}}</button>
      <button type="button" *ngIf="dialogData.actionButtonText" (click)="save()"
        class="btn btn-primary">{{dialogData.actionButtonText}}</button>
    </div>

import { NgModule } from '@angular/core';
import { FormPopupComponent } from './form-popup/form-popup.component';
// removed ng2-nouislider if want to use npm i ng2-nouislider
// import { NouisliderModule } from 'ng2-nouislider';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { DynamicDropdownComponent } from './dynamic-dropdown/dynamic-dropdown.component';
import { DynamicMultiselectDropdownComponent } from './dynamic-multiselect-dropdown/dynamic-multiselect-dropdown.component';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
// import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import { MediaViewerComponent } from './media-viewer/media-viewer.component';
import { CommonFormModule } from '../common/commonModules';
import { PaymentDefinitionComponent } from "./payment-definition/payment-definition.component";
import { MyVideosSelectorComponent } from './my-videos-selector/my-videos-selector.component';
import { GlobalDialogComponent } from './global-dialog/global-dialog-component';
import { TimezoneComponent } from './timezone/timezone.component';
import { FileSelectorComponent } from './file-selector/file-selector.component';
import {AutocompleteLibModule} from 'angular-ng-autocomplete';
import {IntellisenseInputComponent} from './intellisense-input/intellisense-input.component';
import { GoogleCalendarComponent } from './google-calendar/google-calendar.component'
import { SendRequestComponent } from './send-request/send-request.component';
import { PlansListComponent } from './plans-list/plans-list.component';
import { TConvertPipe } from './pipes/t-convert.pipe';
import { EmailSendingPopupComponent } from './email-sending-popup/email-sending-popup.component';

@NgModule({
  declarations: [
    FormPopupComponent,
    TimezoneComponent,
    DynamicDropdownComponent,
    DynamicMultiselectDropdownComponent,
    MediaViewerComponent,
    PaymentDefinitionComponent,
    GlobalDialogComponent,
    MyVideosSelectorComponent,
    FileSelectorComponent,
    IntellisenseInputComponent,
    GoogleCalendarComponent,
    SendRequestComponent,
    PlansListComponent,
    TConvertPipe,
    EmailSendingPopupComponent
  ],
  imports: [
    NgMultiSelectDropDownModule,
    AutocompleteLibModule,
    // OwlDateTimeModule,
    // OwlNativeDateTimeModule,
    // NouisliderModule,
    NgxDaterangepickerMd,

    // common module and form module 
    CommonFormModule,
  ],
  exports: [
    CommonFormModule,
    IntellisenseInputComponent,
    FormPopupComponent,
    EmailSendingPopupComponent,
    DynamicDropdownComponent,
    DynamicMultiselectDropdownComponent,
    MediaViewerComponent,
    PaymentDefinitionComponent,
    MyVideosSelectorComponent,
    GlobalDialogComponent,
    TimezoneComponent,
    FileSelectorComponent,
    GoogleCalendarComponent,
    SendRequestComponent,
    PlansListComponent,
    PaymentDefinitionComponent,
    TConvertPipe
  ],
  providers: []
})
export class SharedModule { }

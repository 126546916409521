import { Injectable } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import * as moment from 'moment';
import { noWhitespaceValidator } from '../authentication/service/passwardMatch';
// import { Config } from '../common/config/config';

// import { QuestionBase } from './question-base';

@Injectable()
export class FormGeneratorService {
  constructor() { }

  toFormGroup(questions: Array<any>) {
    const group: any = {};

    questions.forEach(question => {
      if (question.inputType == 'dateOnly' ||
        question.inputType == 'dropdown' ||
        question.inputType == 'boolean' ||
        question.inputType == 'date' ||
        question.inputType == 'time' ||
        question.inputType == 'text' ||
        question.inputType == 'textarea' ||
        question.inputType == 'number' ||
        question.inputType == 'multiSelect' 
        ) {
        group[question.colName] = !question.properties?.disabled ?
          new FormControl(question.value || (question.inputType == 'dateOnly' || question.inputType == 'date' ? null : ''),
            Validators.compose(this.getValidations(question)))
          : new FormControl(question.value || (question.inputType == 'dateOnly' || question.inputType == 'date' ? null : ''));

        if (question.inputType == 'dateOnly' || question.inputType == 'date' && question.value) {
          group[question.colName].setValue(question.properties.dateTime ? new Date(question.value).toISOString().slice(0,16):moment(question.value).format('yyyy-MM-DD'));

        }
      }else if( question.inputType == 'file'){
        group[question.colName] = new FormControl()
      }
      // {validator: MustMatch('startData', 'endDate')}
    });
    return new FormGroup(group,);
  }

  getValidations(question) {
    let validattions = [];
    // if(question.required && (question.inputType=='text' || question.inputType=='textarea' || question.inputType=='number' || question.inputType=='time')){
    //   // insert white space check
    //   validattions.push(noWhitespaceValidator);
    // }
    if (question.required && !question.dependent) {
      validattions.push(Validators.required);
    }
    if (question.minLength) {
      validattions.push(Validators.minLength(question.minLength));
    }
    if (question.maxLength) {
      validattions.push(Validators.maxLength(question.maxLength));
    }
    if (question.min && !question.dependant) {
      validattions.push(Validators.min(question.min));
    }
    if (question.max && !question.dependant) {
      validattions.push(Validators.max(question.max));
    }
    return validattions
  }
  
}
import { Component, OnInit, HostListener } from '@angular/core';
import { Router } from '@angular/router';
declare var $: any;

import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { CommonStore } from 'src/app/store/common.store';
import { ProfileStore } from 'src/app/store/userProfileStore.store';

@Component({
  selector: 'app-full-layout',
  templateUrl: './full.component.html',
  styleUrls: ['./full.component.scss']
})
export class FullComponent implements OnInit {
  public config: PerfectScrollbarConfigInterface = {};
  dismissMobile:boolean=false;
  year:any
  constructor(
    public router: Router,
    public commonStore:CommonStore,
    public profileStore:ProfileStore) {
      this.year=new Date().getFullYear()
     }

  tabStatus = 'justified';

  public isCollapsed = false;

  public innerWidth: any;
  public defaultSidebar: any;
  public showSettings = false;
  public showMobileMenu = false;
  public expandLogo = false;

  options = {
    theme: 'light', // two possible values: light, dark
    dir: 'ltr', // two possible values: ltr, rtl
    layout: 'vertical', // fixed value. shouldn't be changed.
    sidebartype: 'full', // four possible values: full, iconbar, overlay, mini-sidebar
    sidebarpos: 'fixed', // two possible values: fixed, absolute
    headerpos: 'fixed', // two possible values: fixed, absolute
    boxed: 'full', // two possible values: full, boxed
    navbarbg: 'skin6', // six possible values: skin(1/2/3/4/5/6)
    sidebarbg: 'skin2', // six possible values: skin(1/2/3/4/5/6)
    logobg: 'skin6' // six possible values: skin(1/2/3/4/5/6)
  };

  Logo() {
    this.expandLogo = !this.expandLogo;
  }
  closeAlert(alert) {
    this.commonStore.closeNotifier(alert);
  }
dismiss(){
  this.dismissMobile=true
}
  ngOnInit() {
    if (this.router.url === '/') {
      this.router.navigate(['/dashboard/classic']);
    }
    this.defaultSidebar = this.options.sidebartype;
    this.handleSidebar();
    if(this.profileStore.userData?.role.match('Employee')){
      this.options.sidebarbg='skin2'
    }
    if(this.profileStore.userData?.role.match('Employer') || this.profileStore.userData?.role.match('Company')){
      this.options.sidebarbg='skin1'
    }
    if(this.profileStore.userData?.role.match('Service')){
      this.options.sidebarbg='skin4'
    }
    if(this.profileStore.userData?.role.match('Admin')){
      this.options.sidebarbg='skin6'
    }

  }
  detectAndroidMob() {
    const toMatch = [
        /Android/i,
        // /webOS/i,
        // /iPhone/i,
        // /iPad/i,
        // /iPod/i,
        // /BlackBerry/i,
    ];
    
    return toMatch.some((toMatchItem) => {   
         
        return navigator.userAgent.match(toMatchItem);
    });
}
detectAppleMob() {
  const toMatch = [
      // /Android/i,
      // /webOS/i,
      /iPhone/i,
      /iPad/i,
      /iPod/i,
      // /BlackBerry/i,
  ];
  
  return toMatch.some((toMatchItem) => {   
      return navigator.userAgent.match(toMatchItem);
  });
}

  @HostListener('window:resize', ['$event'])
  onResize(event: string) {
    this.handleSidebar();
  }

  handleSidebar() {
    this.innerWidth = window.innerWidth;
    switch (this.defaultSidebar) {
      case 'full':
      case 'iconbar':
        if (this.innerWidth < 1170) {
          this.options.sidebartype = 'mini-sidebar';
        } else {
          this.options.sidebartype = this.defaultSidebar;
        }
        break;

      case 'overlay':
        if (this.innerWidth < 767) {
          this.options.sidebartype = 'mini-sidebar';
        } else {
          this.options.sidebartype = this.defaultSidebar;
        }
        break;

      default:
    }
  }

  toggleSidebarType() {
    switch (this.options.sidebartype) {
      case 'full':
      case 'iconbar':
        this.options.sidebartype = 'mini-sidebar';
        break;

      case 'overlay':
        this.showMobileMenu = !this.showMobileMenu;
        break;

      case 'mini-sidebar':
        if (this.defaultSidebar === 'mini-sidebar') {
          this.options.sidebartype = 'full';
        } else {
          this.options.sidebartype = this.defaultSidebar;
        }
        break;

      default:
    }
  }
}

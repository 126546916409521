import { Component, ElementRef, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-payment-definition',
  templateUrl: './payment-definition.component.html',
  styleUrls: ['./payment-definition.component.css']
})
export class PaymentDefinitionComponent implements OnInit {
  @Input() plan: any;
  @Output() onPayment: EventEmitter<any> = new EventEmitter<any>();
  @Output() close: EventEmitter<string> = new EventEmitter<string>();
  paymentForm: FormGroup;
  submitted: boolean;

  constructor(public fb: FormBuilder,
    private el: ElementRef,
  ) {
    this.paymentForm = this.fb.group({
      card: ['', Validators.compose([Validators.pattern('^(0|[1-9][0-9]*)$'), Validators.minLength(8),Validators.maxLength(19)])],
      expiry: ['', Validators.compose([Validators.pattern('^((0[1-9])|(1[0-2]))[\/\.\-]*((2[1-9]))$')])],
      cvv: ['', Validators.compose([Validators.pattern('^(0|[0-9]*)$'), Validators.minLength(3), Validators.maxLength(4)])],
    })
  }

  ngOnInit(): void {
  }

  payment() {
    this.submitted=true;
    if (this.paymentForm.valid) {
      this.onPayment.emit(this.paymentForm.value);
    }
  }
  onClose() {
    this.close.emit('close');
  }

  get formcontrols() {
    return this.paymentForm && this.paymentForm.controls;
  }

  isRequired(fieldName) {
    return this.formcontrols && this.formcontrols[fieldName] && this.formcontrols[fieldName].errors && this.formcontrols[fieldName].errors.required;
  }

  isPattern(fieldName) {
    return this.formcontrols && this.formcontrols[fieldName] && this.formcontrols[fieldName].errors && this.formcontrols[fieldName].errors.pattern;
  }
  minLength(fieldName) {
    return this.formcontrols && this.formcontrols[fieldName] && this.formcontrols[fieldName].errors && this.formcontrols[fieldName].errors.minlength;
  }
  maxLength(fieldName) {
    return this.formcontrols && this.formcontrols[fieldName] && this.formcontrols[fieldName].errors && this.formcontrols[fieldName].errors.maxlength;
  }

  isError(fieldName) {
    return this.formcontrols[fieldName] && (this.formcontrols[fieldName].touched || this.submitted) && this.formcontrols[fieldName].errors
  }

  private focusFirstInvalidField() {
    for (const key of Object.keys(this.formcontrols)) {
      if (this.formcontrols[key].invalid) {
        this.focusInputField(key);
        break;
      }
    }
  }
  private focusInputField(key: string) {
    const invalidControl = this.el.nativeElement.querySelector('[formcontrolname="' + key + '"]');
    invalidControl.focus();
  }

}

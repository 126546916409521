import { Component, Input, OnInit } from '@angular/core';
import * as moment from 'moment';
import { Config } from 'src/app/common/config/config';

declare var gapi: any
@Component({
  selector: 'google-calendar',
  templateUrl: './google-calendar.component.html',
  styleUrls: ['./google-calendar.component.css']
})
export class GoogleCalendarComponent implements OnInit {

  @Input() action: any;
  @Input() data: any = { action: '' }

  constructor() { }

  ngOnInit(): void {
    gapi.load("client:auth2", function () {
      gapi.auth2.init({ client_id: Config.secretKeys.googleClientId });
    });

  }
  authenticate = () => {
    return gapi.auth2.getAuthInstance()
      .signIn({ scope: "https://www.googleapis.com/auth/calendar https://www.googleapis.com/auth/calendar.readonly" })
      .then(function (res) {
        console.log("Sign-in successful", res);

      },
        function (err) { console.error("Error signing in", err); });
  }
  loadClient = () => {
    gapi.client.setApiKey(Config.secretKeys.googleApiKey);
    let self=this;
    return gapi.client.load("https://content.googleapis.com/discovery/v1/apis/calendar/v3/rest")
      .then((res)=> {
        console.log("GAPI client loaded for API");
        self.execute()
      },
        function (err) { console.error("Error loading GAPI client for API", err); });
  }
  // Make sure the client is loaded and sign-in is complete before calling this method.
  execute() {
    let data = this.getCustomActionBody();
    console.log('date', moment(data.params.data['interviewDate']).format('YYYY-MM-DD'));
    console.log('title', this.data.data);

    return gapi.client.calendar.events.insert({
      "calendarId": "primary",
      "sendNotifications": true,
      "sendUpdates": "externalOnly",
      "resource": {
        'summary': data?.params?.data['title'],
        'description': this.data?.data['message'] + "\n Meeting Link: "+this.data.data['link'],
        "end": {
          "date": moment(data.params.data['interviewDate']).add(60,'minutes').format('YYYY-MM-DD')
        },
        "start": {
          date:moment(data.params.data['interviewDate']).format('YYYY-MM-DD')
        },
        // if you have proper meet link
        // "source": {
        //   "url": this.data.data['link'],
        //   "title": "Google meet link"
        // }
        // to add meet link on time
        // "conferenceDataVersion": 1,
        // "conferenceData": {
        //   "createRequest": {
        //     "conferenceSolutionKey": {
        //       "type": "hangoutsMeet"
        //     },
        //     "requestId": 'hsaiud3298yiuh'
        //   }
        // }
      }
    })
      .then((response)=> {
        // Handle the results here (response.result has the parsed body).
        console.log("Response", response);
        window.open(response?.result?.htmlLink);
        this.signOut()
      },
        function (err) { console.error("Execute error", err); });
  }

  private getCustomActionBody() {
    let fields = this.getFields(this.data.data, this.data.action.properties);
    let object = {};
    fields.forEach(x => {
      object[x.colName] = x.value;
    });

    let body = {
      params: {
        tableName: this.data.action.view,
        data: object
      }
    };
    return body;
  }

  getFields(data, properties) {
    let fields = []
    properties.forEach(x => {
      let field = {}
      if (x.fromProperty != 'currentUserId') {
        field = {
          "colName": x.toProperty, "label": "", "inputType": "text", "value": data[x.fromProperty], "properties": { "string": true, "readOnly": false, "required": false }
        }
      }

      fields.push(field);
    })
    return fields;
  }

  
  signOut(): void {
    var auth2 = gapi.auth2.getAuthInstance();
    auth2.signOut().then(function () {
      console.log('User signed out.');
    });
  }

}

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { ProfileStore } from '../store/userProfileStore.store';

@Injectable({providedIn: 'root'})
export class LandingPageGuard implements CanActivate {
    constructor(public router:Router,public profileStore:ProfileStore) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        if(!this.profileStore.userData || !this.profileStore.userData.id || !this.profileStore.userData.sessionToken){
            return true;
        }else{
            this.router.navigate(['auth/dashboard'])
            return false;
        }
    }
}
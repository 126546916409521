export const ErrorMessages ={
        name:{
            required:'Organization Name Is Required',
            whitespace:'Please Provide Valid Information'
        },
        organization:{
            required:'Organization Name Is Required',
            whitespace:'Please Provide Valid Information'
        },
        contactName:{
          whitespace:'Please Provide Valid Information'
        },
        hrcontact:{
          whitespace:'Please Provide Valid Information'
        },
        email: {
            required: 'Email Is Required',
            email: 'Email Pattern Is Invalid'
          },
        address:{
          whitespace:'Please Provide Valid Information'
        },
          password: {
            required: 'Password Is Required',
           
          },
          confirmPassword: {
            required: 'Confirm Password Is Required',
            mustMatch: 'Password does not match',
            confirmedValidator:'Password does not match'
          },
          newPassword:{
            required: 'Password Is Required',
            mustMatch: 'Password does not match'
          },
          firstName: {
            required: 'First Name Is Required',
            pattern:'Only Alphabets Allowed',
            whitespace:'Please Provide Valid Information'
          },
          middleName: {
            required: 'Middle Name Is Required',
            whitespace:'Please Provide Valid Information',
            pattern:'Only Alphabets Allowed'
          },
          lastName: {
            required: 'Last Name Is Required',
            whitespace:'Please Provide Valid Information',
            pattern:'Only Alphabets Allowed'
          },
          phoneNumber:{
            pattern: 'Phone Number Pattern Is Invalid'
          },
          country:{
            required: 'Country Is Required'
          },
          package:{
            required:'Package is Required'
          },
          terms:{
            required:'Please accept terms and conditions'
          },
          MasterExpertiseId:{
            required:'Expertise are required'
          },
          videolink:{
            required:'Link Is Required',
            noSpace:'Please Input Valid URL '
          },
          rate:{
            whitespace:'Please Provide Valid Information'
          },
          heardThrough:{
            required:'This field is required',
          },
          other:{
            required:'This field is required',
            // min:'min',
            // max
          }
    
} 
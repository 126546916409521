<!-- ============================================================== -->
<!-- toggle and nav items -->
<!-- ============================================================== -->
<ul class="navbar-nav float-left mr-auto">
  <li class="nav-item d-none d-md-block">
    <a (click)="toggleSidebar.emit()" class="nav-link sidebartoggler waves-effect waves-light"
      href="javascript:void(0)">
      <i class="ti-menu"></i>
    </a>
   
  </li>
  <li class="nav-item d-none d-md-block">
    
    <a class="nav-link sidebartoggler waves-effect waves-light"
    href="javascript:void(0)">
    <!-- <i class="ti-menu"></i> -->
    <b>{{getRole()}}</b>
  </a>
      
    
   
  </li> 
   
</ul>
<!-- ============================================================== -->
<!-- Right side toggle and nav items -->
<!-- ============================================================== -->
<ul class="navbar-nav float-right">
  <!-- ============================================================== -->
  <!-- Search -->
  <!-- ============================================================== -->
  <!-- <li class="nav-item search-box">
        <a (click)="showSearch = !showSearch" class="nav-link waves-effect waves-dark" href="javascript:void(0)">
            <i class="ti-search"></i>
        </a>
        <form class="app-search position-absolute" [ngClass]="{'show-search': showSearch}">
            <input type="text" class="form-control" placeholder="Search &amp; enter">
            <a class="srh-btn" (click)="showSearch = !showSearch">
                <i class="ti-close"></i>
            </a>
        </form>
    </li> -->
  <!-- ============================================================== -->
  <!-- Comment -->
  <!-- ============================================================== -->
  <li class="nav-item dropdown" ngbDropdown placement="bottom-right">
    <a ngbDropdownToggle class="nav-link dropdown-toggle waves-effect waves-dark" href="javascript:void(0)"
      data-toggle="dropdown" (click)="openNotifications()" aria-haspopup="true" aria-expanded="false">
      <i class="mdi mdi-bell"></i>
      <div class="notify" *ngIf="isNewNotification()"> <span class="heartbit"></span> <span class="point"></span> </div>
    </a>
    <div class="dropdown-menu dropdown-menu-right mailbox" ngbDropdownMenu>
      <ul class="list-style-none">
        <li class="border-bottom">
          <div class="drop-title">
            Notifications

          </div>
          <a style="position: absolute; top:8px;right:10px" (click)="onReadNotifications()" role="button"><small
              style="cursor: pointer;">Mark All Read</small></a>
        </li>
        <li>
          <div class="message-center notifications" [perfectScrollbar]="scrollConfig">
            <!-- Message -->
            <a href="javascript:void(0)" [ngStyle]="notification.read==0 ? {'background-color': '#ebf3f5'}:''"
            
              class="message-item" *ngFor="let notification of notificationsToShow;let i=index">
              <!-- <span class="btn btn-circle {{notification.btn}}">
                <i class="{{notification.icon}}"></i>
              </span> -->
              <span class="mail-contnet" (click)="goTo(notification)">
                <h5 class="message-title">{{notification.type}}</h5>
                <span class="mail-desc">
                  <span>
                    <span *ngIf="showMore!=i">
                      <div class="" [innerHTML]="notification.msgString |slice:0:60"></div>
                      <!-- {{notification.msgString |slice:0:60}} -->
                    </span>
                    <span *ngIf="showMore==i">
                      <div class="" [innerHTML]="notification.msgString"></div>
                      <!-- {{notification.msgString}} -->
                    </span>

                    <a *ngIf="showMore!=i && notification.msgString.length>60" (click)="expand(i)"> <strong> Show More </strong></a>
                  </span>
                  
                  </span>
                <span class="time">{{notification.createdAt | date:'short'}}</span>

              </span>
              <!-- <i class="mdi mdi-delete text-danger"></i> -->
            </a>
          </div>
        </li>
        <li *ngIf="dataCount!=notifications.length">
          <a class="nav-link text-center m-b-5" href="javascript:void(0);" (click)="loadMore()">
            <strong>Load More</strong>
            <i class="fa fa-angle-right"></i>
          </a>
        </li>
      </ul>
    </div>
  </li>
  <!-- ============================================================== -->
  <!-- End Comment -->
  <!-- ============================================================== -->
  <!-- ============================================================== -->
  <!-- Messages -->
  <!-- ============================================================== -->
  <!-- <li class="nav-item dropdown" ngbDropdown placement="bottom-right">
        <a ngbDropdownToggle class="nav-link dropdown-toggle waves-effect waves-dark" href="javascript:void(0)" id="2" data-toggle="dropdown"
            aria-haspopup="true" aria-expanded="false">
            <i class="mdi mdi-email"></i>
            <div class="notify"> <span class="heartbit"></span> <span class="point"></span> </div>
        </a>
        <div class="dropdown-menu dropdown-menu-right mailbox" aria-labelledby="2" ngbDropdownMenu>
            <ul class="list-style-none">
                <li class="border-bottom">
                    <div class="drop-title">Messages</div>
                </li>
                <li>
                    <div class="message-center message-body" [perfectScrollbar]="config">
                        <a href="javascript:void(0)" class="message-item" *ngFor="let mymessage of mymessages">
                            <span class="user-img">
                                <img src="{{mymessage.useravatar}}" alt="user" class="rounded-circle">
                                <span class="profile-status {{mymessage.status}} pull-right"></span>
                            </span>
                            <span class="mail-contnet">
                                <h5 class="message-title">{{mymessage.from}}</h5>
                                <span class="mail-desc">{{mymessage.subject}}</span>
                                <span class="time">{{mymessage.time}}</span>
                            </span>
                        </a>
                    </div>
                </li>
                <li>
                    <a class="nav-link text-center link" href="javascript:void(0);">
                        <b>See all e-Mails</b>
                        <i class="fa fa-angle-right"></i>
                    </a>
                </li>
            </ul>
        </div>
    </li> -->
  <!-- ============================================================== -->
  <!-- End Messages -->
  <!-- ============================================================== -->
  <!-- ============================================================== -->
  <!-- mega menu -->
  <!-- ============================================================== -->
  <!-- <li class="nav-item mega-dropdown" ngbDropdown [autoClose]="false">
        <a ngbDropdownToggle class="nav-link waves-effect waves-dark" href="javascript:void(0)" data-toggle="dropdown" aria-haspopup="true"
            aria-expanded="false">
            <i class="mdi mdi-view-grid"></i>
        </a>
        <div class="dropdown-menu" ngbDropdownMenu>
            <div class="mega-dropdown-menu row">
                <div class="col-lg-3 col-xlg-2 m-b-30">
                    <h4 class="m-b-20">CAROUSEL</h4>
                    <ngb-carousel>
                        <ng-template ngbSlide>
                            <img src="assets/images/big/img1.jpg" class="img-fluid" alt="Random first slide">
                            <div class="carousel-caption">
                                <h3 class="text-white font-bold">First slide label</h3>
                            </div>
                        </ng-template>
                        <ng-template ngbSlide>
                            <img src="assets/images/big/img2.jpg" class="img-fluid" alt="Random second slide">
                            <div class="carousel-caption">
                                <h3 class="text-white font-bold">Second slide label</h3>
                            </div>
                        </ng-template>
                        <ng-template ngbSlide>
                            <img src="assets/images/big/img3.jpg" class="img-fluid" alt="Random third slide">
                            <div class="carousel-caption">
                                <h3 class="text-white font-bold">Third slide label</h3>
                            </div>
                        </ng-template>
                    </ngb-carousel>
                </div>
                <div class="col-lg-3 m-b-30">
                    <h4 class="m-b-20">ACCORDION</h4>
                    <ngb-accordion [closeOthers]="true" activeIds="static-1" class="accordion nav-accordion">
                        <ngb-panel id="static-1" title="Simple">
                            <ng-template ngbPanelContent>
                                Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid.
                            </ng-template>
                        </ngb-panel>
                        <ngb-panel id="static-2">
                            <ng-template ngbPanelTitle>
                                <span>&#9733;
                                    <b>Fancy</b> title &#9733;</span>
                            </ng-template>
                            <ng-template ngbPanelContent>
                                Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid.
                            </ng-template>
                        </ngb-panel>
                        <ngb-panel id="static-3" title="Disabled" [disabled]="true">
                            <ng-template ngbPanelContent>
                                Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid.
                            </ng-template>
                        </ngb-panel>
                    </ngb-accordion>
                </div>
                <div class="col-lg-3  m-b-30">
                    <h4 class="m-b-20">CONTACT US</h4>
                    <form>
                        <div class="form-group">
                            <input type="text" class="form-control" id="exampleInputname1" placeholder="Enter Name"> </div>
                        <div class="form-group">
                            <input type="email" class="form-control" placeholder="Enter email"> </div>
                        <div class="form-group">
                            <textarea class="form-control" id="exampleTextarea" rows="3" placeholder="Message"></textarea>
                        </div>
                        <button type="submit" class="btn btn-info">Submit</button>
                    </form>
                </div>
                <div class="col-lg-3 col-xlg-4 m-b-30">
                    <h4 class="m-b-20">List style</h4>
                    <ul class="list-style-none">
                        <li>
                            <a href="javascript:void(0)">
                                <i class="fa fa-check text-success"></i> You can give link</a>
                        </li>
                        <li>
                            <a href="javascript:void(0)">
                                <i class="fa fa-check text-success"></i> Give link</a>
                        </li>
                        <li>
                            <a href="javascript:void(0)">
                                <i class="fa fa-check text-success"></i> Another Give link</a>
                        </li>
                        <li>
                            <a href="javascript:void(0)">
                                <i class="fa fa-check text-success"></i> Forth link</a>
                        </li>
                        <li>
                            <a href="javascript:void(0)">
                                <i class="fa fa-check text-success"></i> Another fifth link</a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </li> -->
  <!-- ============================================================== -->
  <!-- End mega menu -->
  <!-- ============================================================== -->
  <!-- ============================================================== -->
  <!-- User profile and search -->
  <!-- ============================================================== -->
  <li class="nav-item" ngbDropdown placement="bottom-right">
    <a ngbDropdownToggle class="nav-link text-muted waves-effect waves-dark pro-pic" href="javascript:void(0)"
      data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
      <!-- <img src="assets/images/users/1.jpg" alt="user" class="rounded-circle" width="31"> -->
      <app-media-viewer [type]="'p-mini'"
        [key]="profileStore.userData && profileStore.userData.photo && profileStore.userData.photo!={} ? profileStore.userData.photo:null">
      </app-media-viewer>
    </a>
    <div class="dropdown-menu-right user-dd" ngbDropdownMenu>
      <div class="d-flex no-block align-items-center p-15  m-b-10">
        <div class="">
          <!-- <img src="assets/images/users/1.jpg" alt="user" class="rounded" width="70"> -->
          <app-media-viewer [type]="'p-mini-2'"
            [key]="profileStore.userData && profileStore.userData.photo && profileStore.userData.photo!={} ? profileStore.userData.photo:null">
          </app-media-viewer>
        </div>
        <div class="m-l-10">
          <h4 class="m-b-0 font-15">
            {{profileStore.userData && profileStore.userData.organization ? profileStore.userData.organization:''}}
          
          </h4>
          <h4 class="m-b-0 font-15">
            {{profileStore.userData && profileStore.userData.firstName ? profileStore.userData.firstName:profileStore.userData ? profileStore.userData.name:''}}
          </h4>
          <p class="text-muted m-b-0 font-12">
            {{profileStore.userData && profileStore.userData.email ? profileStore.userData.email:''}}</p>
          <a *ngIf="profileStore && profileStore.userData && profileStore.userData?.role.match('Employer') && config.payment"
            href="javascript:void(0)" [routerLink]="'plans'" class="btn btn-danger btn-rounded btn-sm m-t-5">Plans</a>
           
        </div>
        
      </div>
      <div class="m-l-20 text-muted font-12">
        <b>Current Plan Title</b>
        <p>
        {{(profileStore.userData && profileStore.userData.role ? profileStore.userData.role:'') | replaceUnderscore}}
      </p>
      </div>
      <a class="dropdown-item" href="javascript:void(0)" [routerLink]="'/resume'">
        <i class="ti-user m-r-5 m-l-5"></i>  Profile</a>
      <a class="dropdown-item" href="javascript:void(0)" [routerLink]="'resume/change-password'">
        <i class="ti-key m-r-5 m-l-5"></i> Change Password</a>
      <a class="dropdown-item" href="javascript:void(0)" (click)="onLogout()">
        <i class="fa fa-power-off m-r-5 m-l-5"></i> Logout</a>
    </div>
  </li>
  <!-- ============================================================== -->
  <!-- User profile and search -->
  <!-- ============================================================== -->
</ul>

import { Component, Input, OnInit } from '@angular/core';
import { Observable, of } from 'rxjs';
import { GenericCallsService } from 'src/app/appService/generic-calls.service';
import { S3FilesStore } from 'src/app/store/signedFiles.store';

@Component({
  selector: 'app-media-viewer',
  templateUrl: './media-viewer.component.html',
  styleUrls: ['./media-viewer.component.css']
})
export class MediaViewerComponent implements OnInit {
  @Input() key: string | any;
  @Input() link: string;
  @Input() type: 'image' | 'video' | 'profile' | 'p-mini' | 'p-mini-2' | 'audio'|'reply-image';
  @Input() dimension={width:'80%',height:'370px'}
  liveUrl: Observable<string>;
  loaded: boolean;
  constructor(
    private fService: GenericCallsService,
    public s3FilesStore: S3FilesStore) { }

  ngOnInit(): void {
    if (!this.loaded && this.s3FilesStore.getFileFromStore(this.key)) {
      this.liveUrl = of(this.s3FilesStore.getFileFromStore(this.key));
    } else if (!this.loaded && this.key && typeof(this.key)!='object') {
      this.fService.getSignedUrl(this.key).subscribe((res: any) => {
        this.loaded = true;
        this.s3FilesStore.insertFile(res.result.linkToFile, this.key);
        this.liveUrl = of(res.result.linkToFile);
      },err=>{
        this.liveUrl=of('assets/images/app-default.png')
      })
    }else{
      this.liveUrl=of('assets/images/app-default.png')
    }
    console.log("types",this.type);
  }

  skip(value){
   
      let video: any = document.getElementById("my_video_1");
      video.currentTime += value;
   
  }

}

<div class="form-horizontal">
  <div class="box-body">
    <div class="form-group">
      <label for="group_name" class="col-sm-3 control-label">
        Select time zone</label>
      <div class="col-sm-9">

        <select name="type" placeholder="Reminder Criteria" [(ngModel)]="currentTimeZone.timezone"
          (change)="changeTimeZone(currentTimeZone.timezone)" class="form-control">
          <option *ngFor="let z of timeZoneList" [ngValue]="z.timezone">
            {{z.timezone}}</option>
        </select>
      </div>
    </div>
  </div>
</div>




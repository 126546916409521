import { Component, Input, Output, OnInit, EventEmitter, ElementRef, ViewChildren } from '@angular/core';
import { FormControlName, FormGroup } from '@angular/forms';
import * as moment from 'moment';
import { BehaviorSubject, fromEvent, merge, Observable } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { GenericValidatorService } from 'src/app/appService/generic-validator.service';
import { Config } from 'src/app/common/config/config';



@Component({
  selector: 'app-form-popup',
  templateUrl: './form-popup.component.html',
  styleUrls: ['./form-popup.component.css']
})
export class FormPopupComponent implements OnInit {
  @Input() fieldsArrays: Array<any>=[];
  @Input() title:string;
  @Input() loading:boolean=false;
  @Output() onSave: EventEmitter<any> = new EventEmitter<any>();
  @Output() onClose: EventEmitter<any> = new EventEmitter<any>();
  @Input() dataForm: FormGroup;
  @Input() showCloseIcon: boolean=true;
  @Input() buttonTitle:string='save';
  submitted:boolean;
  pattern = Config.patterns;
  displayMessage: { [key: string]: string } = {};
  $errorMessage: BehaviorSubject<any> = new BehaviorSubject<any>({});
  @ViewChildren(FormControlName, { read: ElementRef }) formInputElements: ElementRef[];
  constructor( private genericValidator: GenericValidatorService) { }

  ngOnInit(): void {
    console.log("called");
    // this.validationErrors();
  }
  changeDateOnly(date) {

  }
  
  ngAfterViewInit(): void {
    // Watch for the blur event from any input element on the form.
    const controlBlurs: Observable<any>[] = this.formInputElements
      .map((formControl: ElementRef) => fromEvent(formControl.nativeElement, 'blur'));

    // Merge the blur event observable with the valueChanges observable
    merge(this.dataForm.valueChanges, ...controlBlurs).pipe(
      debounceTime(80)
    ).subscribe(value => {
      this.displayMessage = this.genericValidator.processMessages(this.dataForm,false,true);
      this.$errorMessage.next(this.displayMessage)
    });
  }
  
  onChangeField(field){
    let index=this.fieldsArrays.findIndex(x=>x.colName==field.colName);
    if(index>-1){
      this.fieldsArrays[index]=field;
    }
  }
  getMinDate(input?) {
    let date = new Date();
    if(input.min){
      if (input.min && !input.dependant && !input['disablePastDate']) {
        return input.min ? input.min : moment(date.setFullYear(1900)).format('yyyy-MM-DD')
      }
      else if(input['disablePastDate']){
        return input.properties.dateTime ? new Date():moment(date).format('yyyy-MM-DD')
      } 
      else if (input.min && input.dependant) {
        return this.dataForm.value[input.min] ? this.dataForm.value[input.min]:null;
      }
    }else{
      return false;
    }
  }
  getMaxDate(input?) {
    if (input.max) {
      if (input.max && !input.dependant) {
        return input.max ? input.max : null;
      }
      else if (input.max && input.dependant) {
        return this.dataForm.value[input.max] ? this.dataForm.value[input.max] :input['disableFutureDate'] ? moment(new Date()).format('yyyy-MM-DD'): null;
      }
    }
    else if(input['disableFutureDate']){
      return input.properties.dateTime ? moment(new Date()).format('yyyy-MM-DD,hh:mm:ss:A'):moment(new Date()).format('yyyy-MM-DD')
    } 
    else {
      return null
    }
  }
  choosedDateTime(data) {

  }

  onChangeRange(data) {

  }
  close(){
    this.onClose.emit('close');
  }
  saveEvent() {
    this.fieldsArrays.forEach(field=>{
      if(!field.properties?.disabled){
        if(field.inputType!='file'){
          field.value= this.dataForm.value[field.colName];
          if(field.value && field.value!='' && field.inputType=='text' || field.inputType=='textarea' || field.inputType == 'tel' )
          {
            field.value=field.value.trim();
          }
        }
      }
    })
    this.submitted=true;
    if(this.dataForm.valid){
      this.onSave.emit(this.fieldsArrays);
    }
    else{
      this.displayMessage = this.genericValidator.processMessages(this.dataForm,true,true);
        this.$errorMessage.next(this.displayMessage);

    }
  }
}

import { Injectable } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { ProfileStore } from '../store/userProfileStore.store';

@Injectable({ providedIn: 'root' })
export class SearchGuard implements CanActivate {
    constructor(private route: ActivatedRoute, public router: Router, public profileStore: ProfileStore) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        if (this.profileStore.userData && this.profileStore.userData.id && this.profileStore.userToken) {
            console.log('user logged in ', state);
            var str = state.url;
            var n = str.lastIndexOf('/');
            var result = str.substring(n + 1);
            var k=result.indexOf(';');
            var result2 = result.substring(k + 1);
            // debugger
            if (state.url.match('search/job-view') && this.profileStore.userData.role.match('Service_Provider'))  {
                this.router.navigate(['auth/resume'])
                return false;
            }
            if (state.url.match('search/job-view') && ((this.profileStore.userData.role.match('Employer') || (this.profileStore.userData.role.match('Employee')) || this.profileStore.userData.role == 'Admin'))) {
                this.router.navigateByUrl('auth/jobs/job-view/'+result);
                return false;
            }
            else if(state.url.match('search/service-view') && (this.profileStore.userData.role.match('Service_Provider') || (this.profileStore.userData.role.match('Employer')  || this.profileStore.userData.role == 'Admin'))){
                this.router.navigateByUrl('auth/service/service-view/'+result);
                return false;
            }
            else if(state.url.match('search/training-view') && (this.profileStore.userData.role.match('Service_Provider') || (this.profileStore.userData.role.match('Employer')  || this.profileStore.userData.role == 'Admin'))){
                this.router.navigateByUrl('auth/upcoming/u-view/'+result);
                return false;
            }
            else if(state.url.match('search/r-employee') && (this.profileStore.userData.role.match('Service_Provider') || (this.profileStore.userData.role.match('Employer') || this.profileStore.userData.role.match('Employee') || this.profileStore.userData.role == 'Admin'))){
                this.router.navigateByUrl('auth/review/employee-resume;'+result2);
                return false;
            }
            else if(state.url.match('search/r-employer') && (this.profileStore.userData.role.match('Service_Provider') || (this.profileStore.userData.role.match('Employer') || this.profileStore.userData.role.match('Employee') || this.profileStore.userData.role == 'Admin'))){
                this.router.navigateByUrl('auth/review/employee-resume;'+result2);
                return false;
            }
            else if(state.url.match('search/r-serviceProvider') && (this.profileStore.userData.role.match('Service_Provider') || (this.profileStore.userData.role.match('Employer') || this.profileStore.userData.role.match('Employee') || this.profileStore.userData.role == 'Admin'))){
                
                this.router.navigateByUrl('auth/review/employee-resume;'+result2);
                return false;
            }

            else if(state.url.match('auth/review/employee-resume') && (!this.profileStore.userData)){
                
               console.log('result2',result2);
                // this.router.navigateByUrl('search/r-serviceProvider;'+result2);
                return false;
            }
            
            
            else{
                return true
            }
            
        } else {
            console.log('user not logged in ', route);
            return true;
        }
    }
}
<div *ngIf="type && type=='profile'">
  <img class="rounded-circle mt-4" src="{{liveUrl | async}}" height="100" width="100">
</div>
<div *ngIf="type && type=='image'">
  <img class="rounded-circle" src="{{liveUrl | async}}" height="70" width="70">
</div>

<div *ngIf="type && type=='reply-image'">
  <img class="rounded-circle" src="{{liveUrl | async}}" height="70" width="70">
</div>
<div *ngIf="type && type=='p-mini'">
  <img src="{{liveUrl | async}}" alt="user" class="rounded-circle" width="31">
</div>
<div *ngIf="type && type=='p-mini-2'">
  <img src="{{liveUrl | async}}" alt="user" class="rounded" width="70">
</div>
<div *ngIf="type && type=='video'" class="mt-4">
  <!-- <video *ngIf="liveUrl" id="my_video_1" class="img" [style.width]="dimension.width" [style.height]="dimension.height" controls preload="none"
  
  data-setup='{ "aspectRatio":"640:267", "playbackRates": [1, 1.5, 2] }'>
  <source src="{{liveUrl | async}}" type='video/mp4' />
  <source src="https://vjs.zencdn.net/v/oceans.webm" type='video/webm' />
</video> -->
<!-- IF client raise issue of forward or rewind we will use this -->
<!-- <span title="Rewind" (click)="skip(-10)"><<</span>  <span title="Forward" (click)="skip(+10)">>></span>  -->
<!-- old code -->
  <video *ngIf="liveUrl" id="video1" class="img" [style.width]="dimension.width" [style.height]="dimension.height" controls>
    <source src="{{liveUrl | async}}">
  </video>
</div>
<div *ngIf="type && type=='audio'" class="mt-4">
  
  <audio *ngIf="liveUrl" id="video2" class="img" [style.width]="dimension.width" [style.height]="dimension.height" controls>
    <source src="{{liveUrl | async}}">
  </audio>
</div>


import { RouteInfo } from './sidebar.metadata';

export const ROUTES: RouteInfo[] = [


  {
    path: 'auth/dashboard',
    title: 'Dashboard',
    icon: 'mdi mdi-gauge',
    class: '',
    role: 'Employer',
    extralink: false,
    submenu: []
  },
  {
    path: 'auth/dashboard',
    title: 'Dashboard',
    icon: 'mdi mdi-gauge',
    class: '',
    role: 'Employee',
    extralink: false,
    submenu: []
  },
  {
    path: 'auth/dashboard',
    title: 'Dashboard',
    icon: 'mdi mdi-gauge',
    class: '',
    role: 'Service_Provider',
    extralink: false,
    submenu: []
  },

  {
    path: 'resume',
    title: 'Resume',
    icon: 'edu-employee',
    class: '',
    role: 'Employee',
    extralink: false,
    prop: 'profile',
    required: true,
    submenu: [
      {
        path: 'profile/views',
        title: 'Profile Views',
        icon: 'mdi mdi-eye',
        class: '',
        role: 'Employee',
        extralink: false,
        submenu: []
      }
    ]
  },
  {
    path: 'resume',
    title: 'Profile',
    icon: 'edu-businessman',
    class: '',
    role: 'Employer',
    prop: 'profile',
    required: true,
    extralink: false,
    submenu: [
      {
        path: 'profile/views',
        title: 'Profile Views',
        icon: 'mdi mdi-eye',
        class: '',
        role: 'Employer',
        extralink: false,
        submenu: []
      },
      {
        path: 'profile/updates',
        title: 'Resume Updates',
        icon: 'mdi mdi-eye',
        class: '',
        role: 'Employer',
        extralink: false,
        submenu: []
      }
    ]
  },
  {
    path: 'video/employer-videos',
    title: 'Upload Video',
    icon: 'edu-video-camera',
    class: '',
    role: 'Employer',
    extralink: false,
    submenu: []
  },
  {
    path: 'resume',
    title: 'Profile',
    icon: 'edu-employee',
    class: '',
    role: 'Admin',
    extralink: false,
    submenu: []
  },
  {
    path: 'resume',
    title: 'Profile',
    icon: 'edu-employee',
    class: '',
    role: 'Service_Provider',
    extralink: false,
    prop: 'profile',
    required: true,
    submenu: []
  },
  {
    path: 'jobs',
    title: 'Jobs',
    icon: 'edu-search',
    class: 'disbale route',
    role: 'Employee',
    extralink: false,
    submenu: [
      {
        path: 'jobs',
        title: 'Jobs',
        icon: 'edu-search',
        class: '',
        role: 'Employee',
        extralink: false,
        submenu: []
      },
      {
        path: 'applied/a-jobs',
        title: 'Applied Jobs',
        icon: 'edu-user',
        class: '',
        role: 'Employee',
        extralink: false,
        submenu: []
      },
      {
        path: 'interviews/u-interviews',
        title: 'Upcoming Interviews',
        icon: 'edu-users-group',
        class: '',
        role: 'Employee',
        extralink: false,
        submenu: []
      },
    ]
  },
  {
    path: 'employer/r-employer',
    title: 'Employers',
    icon: 'edu-employee',
    class: 'disable route',
    role: 'Employee',
    extralink: false,
    submenu: [
      {
        path: 'employer/r-employer',
        title: 'Review Employers',
        icon: 'mdi mdi-eye',
        class: '',
        role: 'Employee',
        extralink: false,
        submenu: []
      }
    ]
  },

  {
    path: 'jobs/postjob',
    title: 'Jobs',
    icon: 'mdi mdi-send',
    class: 'disable route',
    role: 'Employer',
    extralink: false,
    submenu: [
      {
        path: 'jobs/create',
        title: 'Post Jobs',
        icon: 'mdi mdi-send',
        class: '',
        role: 'Employer',
        extralink: false,
        submenu: []
      },
      {
        path: 'jobs/postjob',
        title: 'Review Jobs',
        icon: 'edu-hammer',
        class: '',
        role: 'Employer',
        extralink: false,
        submenu: []
      }
    ]
  },
  {
    path: 'host/h-dev',
    title: 'Professional Development',
    icon: 'edu-hourglass',
    class: 'disable route',
    role: 'Employee',
    extralink: false,
    submenu: [
      {
        path: 'host/h-dev',
        title: 'Host Professional Development',
        icon: 'edu-alarm',
        class: '',
        role: 'common',
        extralink: false,
        submenu: []
      },
      {
        path: 'upcoming/u-dev',
        title: 'Upcoming Professional Development',
        icon: 'edu-hourglass',
        class: '',
        role: 'common',
        extralink: false,
        submenu: []
      },
      {
        path: 'registered/a-training',
        title: 'Registered Development',
        icon: 'edu-clock',
        class: '',
        role: 'common',
        extralink: false,
        submenu: []
      },
    ]
  },
  {
    path: 'employee/r-employees',
    title: 'Employees',
    icon: 'edu-employee',
    class: 'disable route',
    role: 'Employer',
    extralink: false,
    submenu: [
      {
        path: 'employee/featured-employees',
        title: 'Featured Employees',
        icon: 'edu-user',
        class: '',
        role: 'Employer',
        extralink: false,
        submenu: []
      },
      {
        path: 'employee/r-employees',
        title: 'Review Employees',
        icon: 'edu-user',
        class: '',
        role: 'Employer',
        extralink: false,
        submenu: []
      },
      {
        path: 'jobs/e-interviews',
        title: 'Employee Interviews',
        icon: 'fa fa-users',
        class: '',
        role: 'Employer',
        extralink: false,
        submenu: []
      },
    ]
  },
  {
    path: 'host/h-dev',
    title: 'PD & Services',
    icon: 'edu-hammer',
    class: 'disable route',
    role: 'Employer',
    extralink: false,
    submenu: [
      {
        path: 'host/h-dev',
        title: 'Host Professional Development',
        icon: 'edu-alarm',
        class: '',
        role: 'common',
        extralink: false,
        submenu: []
      },
      {
        path: 'upcoming/u-dev',
        title: 'Upcoming Professional Development',
        icon: 'edu-hourglass',
        class: '',
        role: 'common',
        extralink: false,
        submenu: []
      },
      {
        path: 'registered/a-training',
        title: 'Registered Development',
        icon: 'edu-clock',
        class: '',
        role: 'common',
        extralink: false,
        submenu: []
      },
      {
        path: 'service/createService',
        title: 'Post Service',
        icon: 'edu-hammer',
        class: '',
        role: 'Employer',
        extralink: false,
        submenu: []
      },
      {
        path: 'service/post-service',
        title: 'Review Services',
        icon: 'edu-hammer',
        class: '',
        role: 'Employer',
        extralink: false,
        submenu: []
      },
      {
        path: 'review/r-s-provider',
        title: 'Review Service Providers',
        icon: 'fa fa-user-circle',
        class: '',
        role: 'Employer',
        extralink: false,
        submenu: []
      },
      {
        path: 'service/s-p-interviews',
        title: 'Service Provider Interviews',
        icon: 'fa fa-users',
        class: '',
        role: 'Employer',
        extralink: false,
        submenu: []
      }
    ]
  },

  {
    path: 'resume/screener/employer-screener',
    title: 'Screener',
    icon: 'edu-photo-camera',
    class: '',
    role: 'Employer',
    extralink: false,
    dashboard: true,
    required: true,
    prop: 'screener',
    submenu: []
  },
  {
    path: 'resume/screener/screener',
    title: 'Screener',
    icon: 'edu-photo-camera',
    class: '',
    role: 'Employee',
    extralink: false,
    dashboard: true,
    required: true,
    prop: 'screener',
    submenu: []
  },
  {
    path: 'resume/education',
    title: 'Education',
    icon: 'edu-education',
    class: '',
    role: 'Employee',
    extralink: false,
    dashboard: true,
    required: true,
    prop: 'educationalDetails',
    submenu: []
  },
  {
    path: 'resume/employment',
    title: 'Employment History',
    icon: 'edu-businessman',
    class: '',
    role: 'Employee',
    extralink: false,
    dashboard: true,
    required: true,
    prop: 'employmentHistory',
    submenu: []
  },
  {
    path: 'resume/project',
    title: 'Work Samples',
    icon: 'edu-work',
    class: '',
    role: 'Employee',
    extralink: false,
    dashboard: true,
    required: true,
    prop: 'workSamples',
    submenu: []
  },
  {
    path: 'resume/certification',
    title: 'Certifcations',
    icon: 'edu-certificate',
    class: '',
    role: 'Employee',
    extralink: false,
    dashboard: true,
    required: true,
    prop: 'certification',
    submenu: []
  },
  {
    path: 'resume/references',
    title: 'References',
    icon: 'edu-businessman',
    class: '',
    role: 'Employee',
    extralink: false,
    dashboard: true,
    required: true,
    prop: 'references',
    submenu: []
  },


  {
    path: 'resume/project',
    title: 'Work Samples',
    icon: 'edu-work',
    class: '',
    role: 'Service_Provider',
    extralink: false,
    dashboard: true,
    required: true,
    prop: 'workSamples',
    submenu: []
  },

  {
    path: 'resume/about-business',
    title: 'About Bussiness',
    icon: 'mdi mdi-information-outline',
    class: '',
    role: 'Service_Provider',
    extralink: false,
    dashboard: true,
    required: true,
    prop: 'aboutBusiness',
    submenu: []
  },
  {
    path: 'resume/references',
    title: 'References',
    icon: 'edu-businessman',
    class: '',
    role: 'Service_Provider',
    extralink: false,
    dashboard: true,
    required: true,
    prop: 'references',
    submenu: []
  },

  {
    path: 'blog/connection-center',
    title: 'Connection Center',
    icon: 'edu-connection-center',
    class: '',
    role: 'common',
    extralink: false,
    dashboard: false,
    submenu: []
  },
  {
    path: 'blog/connection-center',
    title: 'Connection Center',
    icon: 'edu-connection-center',
    class: '',
    role: 'Admin',
    extralink: false,
    submenu: []
  },

  {
    path: 'blog/connection-center',
    title: 'Connection Center',
    icon: 'edu-circles-loader',
    class: '',
    role: 'common',
    dashboard: true,
    extralink: false,
    submenu: []
  },
  // {
  //   path: 'blog/connection-center',
  //   title: 'Connection Center',
  //   icon: 'edu-connection-center',
  //   class: '',
  //   role:'Admin',
  //   extralink: false,
  //   submenu: []
  // },
  // {
  //   path: 'blog/connection-center',
  //   title: 'Connection Center',
  //   icon: 'edu-connection-center',
  //   class: '',
  //   role:'Employee',
  //   extralink: false,
  //   submenu: []
  // },
  // {
  //   path: 'blog/connection-center',
  //   title: 'Connection Center',
  //   icon: 'edu-connection-center',
  //   class: '',
  //   role:'Employer',
  //   extralink: false,
  //   submenu: []
  // },
  {
    path: 'manage/payments',
    title: 'Manual Payments',
    icon: 'mdi mdi-credit-card',
    class: '',
    role: 'Admin',
    extralink: false,
    submenu: []
  },
  {
    path: 'manage/m-employee',
    title: 'Manage Employees',
    icon: 'mdi mdi-account-search',
    class: '',
    role: 'Admin',
    extralink: false,
    submenu: []
  },
  {
    path: 'manage/m-employer',
    title: 'Manage Employers',
    icon: 'mdi mdi-account-search',
    class: '',
    role: 'Admin',
    extralink: false,
    submenu: []
  },
  {
    path: 'manage/m-s-provider',
    title: 'Manage Service Providers',
    icon: 'mdi mdi-account-search',
    class: '',
    role: 'Admin',
    extralink: false,
    submenu: []
  },
  {
    path: 'manage/m-d-trainings',
    title: 'Manage Development Trainings',
    icon: 'mdi mdi-alarm-plus',
    class: '',
    role: 'Admin',
    extralink: false,
    submenu: []
  },
  {
    path: 'manage/DT-requests',
    title: 'Manage Training Feature',
    icon: 'mdi mdi-settings',
    class: '',
    role: 'Admin',
    extralink: false,
    submenu: []
  },
  {
    path: 'manage/m-jobs',
    title: 'Manage Jobs',
    icon: 'mdi mdi-alarm-plus',
    class: '',
    role: 'Admin',
    extralink: false,
    submenu: []
  },
  {
    path: 'manage/m-services',
    title: 'Manage Services',
    icon: 'mdi mdi-alarm-plus',
    class: '',
    role: 'Admin',
    extralink: false,
    submenu: []
  },
  {
    path: 'uploads/manage-videos',
    title: 'Manage Video Uploads',
    icon: 'mdi mdi-video',
    class: '',
    role: 'Admin',
    extralink: false,
    submenu: []
  },
  // removed by client
  // {
  //   path: 'uploads/m-screener-videos',
  //   title: 'Manage Screener Videos',
  //   icon: 'mdi mdi-video',
  //   class: '',
  //   role:'Admin',
  //   extralink: false,
  //   submenu: []
  // },
  {
    path: 'settings',
    title: 'Settings',
    icon: 'mdi mdi-settings',
    class: '',
    role: 'Admin',
    extralink: false,
    submenu: []
  },
  {
    path: 'media/media-library',
    title: 'Media Library',
    icon: 'edu-video-camera',
    class: '',
    role: 'Employer',
    extralink: false,
    submenu: []
  },
  {
    path: 'media/media-library',
    title: 'Media Library',
    icon: 'edu-video-camera',
    class: '',
    role: 'Employee',
    extralink: false,
    submenu: []
  },
  {
    path: 'media/manage-media',
    title: 'Manage Media Library',
    icon: 'edu-video-camera',
    class: '',
    role: 'Admin',
    extralink: false,
    submenu: []
  },
  {
    path: 'video',
    title: 'Upload Video',
    icon: 'edu-video-camera',
    class: '',
    role: 'Employee',
    extralink: false,
    submenu: []
  },



  {
    path: 'interviews/sp-interviews',
    title: 'Service Upcoming Interviews',
    icon: 'edu-users-group',
    class: '',
    role: 'Service_Provider',
    extralink: false,
    submenu: []
  },
  // {
  //   path: 'service',
  //   title: 'Services',
  //   icon: 'edu-hammer',
  //   class: 'disable route',
  //   role:'Employer',
  //   extralink: false,
  //   submenu: [


  //   ]
  // },
  {
    path: 'service/services',
    title: 'Services',
    icon: 'edu-search',
    class: '',
    role: 'Service_Provider',
    extralink: false,
    submenu: []
  },

  {
    path: 'service/a-service',
    title: 'Applied Services',
    icon: 'edu-user',
    class: '',
    role: 'Service_Provider',
    extralink: false,
    submenu: []
  },
  {
    path: 'saved/s-jobs',
    title: 'Favorite Jobs',
    icon: 'edu-favorite',
    class: '',
    role: 'Employee',
    extralink: false,
    submenu: []
  },
  {
    path: 'service/favorite-services',
    title: 'Favourite Services',
    icon: 'edu-favorite',
    class: '',
    role: 'Service_Provider',
    extralink: false,
    submenu: []
  },
  {
    path: 'service/messages',
    title: 'Messages',
    icon: 'edu-video-chat',
    class: '',
    role: 'Service_Provider',
    extralink: false,
    submenu: []
  },


  {
    path: 'messages/custom-videos',
    title: 'Messages',
    icon: 'edu-video-chat',
    class: '',
    role: 'Employee',
    extralink: false,
    submenu: []
  },

  {
    path: 'messages/custom-question-message',
    title: 'Messages',
    icon: 'edu-video-chat',
    class: '',
    role: 'Employer',
    extralink: false,
    submenu: []
  },

  // {
  //   path: 'registered/a-training',
  //   title: 'Registered Development',
  //   icon: 'edu-clock',
  //   class: '',
  //   role:'common',
  //   extralink: false,
  //   submenu: []
  // },
  {
    path: 'company/m-users',
    title: 'Manage Users',
    icon: 'fa fa-users',
    class: '',
    role: 'Employer',
    extralink: false,
    submenu: []
  }

];


import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFireMessaging} from '@angular/fire/messaging';
import { BehaviorSubject } from 'rxjs'
import { requests } from '../common/config/requests';
// import { AngularFireAuth } from '@angular/fire/auth';

@Injectable()
export class MessagingService {
  currentMessage = new BehaviorSubject(null);
  token: any;
  constructor(
    // private angularFireAuth: AngularFireAuth,
    private angularFireMessaging: AngularFireMessaging,
    public http:HttpClient) {
      this.angularFireMessaging.messaging.subscribe(
        (_messaging) => {
          _messaging.onMessage = _messaging.onMessage.bind(_messaging);
          _messaging.onTokenRefresh = _messaging.onTokenRefresh.bind(_messaging);
        }
      )
    // this.angularFireMessaging.messaging.subscribe(
    //   (_messaging) => {
    //     _messaging.onMessage = _messaging.onMessage.bind(_messaging);
    //     _messaging.onTokenRefresh = _messaging.onTokenRefresh.bind(_messaging);
    //   }
    // )
  }

  requestPermission() {
    if(!localStorage.getItem('fbToken')){
      this.angularFireMessaging.requestToken.subscribe(
        (token) => {
          this.http.post(requests.fbToken,{tokenFB:token}).subscribe();
          // console.log(token);
          this.token=token;
          localStorage.setItem('fbToken',JSON.stringify(token));
        },
        (err) => {
          console.error('Unable to get permission to notify.', err);
        }
      );
    }
  }

  receiveMessage() {
    this.angularFireMessaging.messages.subscribe(
      (payload) => {
        // console.log("new message received. ", payload);
      
        this.currentMessage.next(payload);
      })
  }

  deleteMesage() {
    this.angularFireMessaging.deleteToken(this.token).subscribe(
      (payload) => {
        localStorage.removeItem('fbToken')
        // console.log("token deleted", payload);
      })
  }
}
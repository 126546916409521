import { Routes} from '@angular/router';
import { NotfoundComponent } from './layouts/404/not-found.component';

import { FullComponent } from './layouts/full/full.component';
import { ServerDownComponent } from './layouts/server-down/server-down.component';
import { AuthGuard } from './routingGuards/authGuard';
import { LandingPageGuard } from './routingGuards/landingPageGuard';
import { SearchGuard } from './routingGuards/searchGuard';
import { Unsubscribe } from './layouts/unsubscribe/unsubscribe.component';

export const Approutes: Routes = [
 
  {
    path: 'auth',
    component: FullComponent,
    children: [
      { 
        path: '',
       loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule),canActivate:[AuthGuard] },
      // { path: '', redirectTo: '/resume/profile', pathMatch: 'full' },
      {
        path: 'resume',
        loadChildren: () => import('./resume/resume.module').then(m => m.ResumeModule),canActivate:[AuthGuard]
      },
     
      {
        path: 'video',
        loadChildren: () => import('./videos/videos.module').then(m => m.VideosModule),canActivate:[AuthGuard]
      },
      {
        path: 'jobs',
        loadChildren: () => import('./genaricViews/genaric.module').then(m => m.GenaricModule),canActivate:[AuthGuard]
      },
      {
        path: 'applied',
        loadChildren: () => import('./genaricViews/genaric.module').then(m => m.GenaricModule),canActivate:[AuthGuard]
      },
      {
        path: 'registered',
        loadChildren: () => import('./genaricViews/genaric.module').then(m => m.GenaricModule),canActivate:[AuthGuard]
      },
      {
        path: 'host',
        loadChildren: () => import('./genaricViews/genaric.module').then(m => m.GenaricModule),canActivate:[AuthGuard]
      },
      {
        path: 'upcoming',
        loadChildren: () => import('./genaricViews/genaric.module').then(m => m.GenaricModule),canActivate:[AuthGuard]
      },
      {
        path: 'saved',
        loadChildren: () => import('./genaricViews/genaric.module').then(m => m.GenaricModule),canActivate:[AuthGuard]
      },
      {
        path: 'service',
        loadChildren: () => import('./genaricViews/genaric.module').then(m => m.GenaricModule),canActivate:[AuthGuard]
      },
      {
        path: 'review',
        loadChildren: () => import('./genaricViews/genaric.module').then(m => m.GenaricModule),canActivate:[AuthGuard]
      },
      {
        path: 'manage',
        loadChildren: () => import('./genaricViews/genaric.module').then(m => m.GenaricModule),canActivate:[AuthGuard]
      },
      {
        path: 'interviews',
        loadChildren: () => import('./genaricViews/genaric.module').then(m => m.GenaricModule),canActivate:[AuthGuard]
      },
      {
        path: 'profile',
        loadChildren: () => import('./genaricViews/genaric.module').then(m => m.GenaricModule),canActivate:[AuthGuard]
      },
      {
        path: 'messages',
        loadChildren: () => import('./genaricViews/genaric.module').then(m => m.GenaricModule),canActivate:[AuthGuard]
      },
      {
        path: 'uploads',
        loadChildren: () => import('./genaricViews/genaric.module').then(m => m.GenaricModule),canActivate:[AuthGuard]
      },
      {
        path: 'settings',
        loadChildren: () => import('./settings/settings.module').then(m => m.SettingsModule),canActivate:[AuthGuard]
      },
      {
        path: 'plans',
        loadChildren: () => import('./employer-plans/employer-plans.module').then(m => m.EmployerPlansModule),canActivate:[AuthGuard]
      },
      {
        path: 'company',
        loadChildren: () => import('./static/static.module').then(m => m.StaticModule),canActivate:[AuthGuard]
      },
      {
        path: 'media',
        loadChildren: () => import('./static/static.module').then(m => m.StaticModule),canActivate:[AuthGuard]
      },
      {
        path: 'employee',
        loadChildren: () => import('./static/static.module').then(m => m.StaticModule),canActivate:[AuthGuard]
      },
      {
        path: 'employer',
        loadChildren: () => import('./static/static.module').then(m => m.StaticModule),canActivate:[AuthGuard]
      },
      {
        path: 'blog',
        loadChildren: () => import('./static/static.module').then(m => m.StaticModule),canActivate:[AuthGuard]
      },
      // {
      //   path: 'search',
      //   loadChildren: () => import('./search/search.module').then(m => m.SearchModule)
      // },
      {
        path: '404',
        component: NotfoundComponent
      },
    ]
  },
  {
    path: 'search',
    loadChildren: () => import('./search/search.module').then(m => m.SearchModule),
    canActivate:[SearchGuard]
  },
  {
    path: 'unsubscribe',
    component: Unsubscribe,
    // canActivate:[AuthGuard]
  },
  {
    path: 'server-down',
    component: ServerDownComponent
  },
  {
    path: '',
    loadChildren: () => import('./authentication/authentication.module').then(m => m.AuthenticationModule), canActivate:[LandingPageGuard]
  },
  
  {
    path: '**',
    redirectTo: '/'
  },
  
];

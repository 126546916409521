
import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { Config } from 'src/app/common/config/config';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class MetaSeoService {

  constructor(
    private meta: Meta,
    @Inject(DOCUMENT) private dom
  ) {
    if (environment.SeoEnable) {
      this.addTags();
    }
  }

  addTags() {
    this.meta.addTags([
      {
        name: 'keywords',
        content: 'Edu Openings, jobs, services, freelancers, employer, employee, video messaging, job hiring, teachers, teaching jobs,administration jobs, students, voice messaging, ineterviews, schedule interviews, voice blogs, articles',
      },
      {
        name: 'description',
        content:
          '',
      },
      { name: 'author', content: 'EduOpenings.com' },
    ]);
  }
  updateMetaDescriptionTag(content) {
    if (environment.SeoEnable) {
      Config.SeoTags.description = content + ',' + Config.SeoTags.description;
      this.meta.updateTag({ name: 'description', content: Config.SeoTags.description });
     

    }
  }
  addOGTitle(content) {
    if (environment.SeoEnable) {
      this.meta.updateTag({ property: 'og:title', content: content});
    }
  }

  addOGDescription(content)
  {
    if (environment.SeoEnable) {
      this.meta.updateTag({  property: 'og:description', content: content });
    }

  }

  // addOGDescription(content) {
  //   if (environment.SeoEnable) {
  //     this.meta.addTag({ property: 'og:title', content: content});
  //   }
  // }

  updateMetaKeywordsTag(content) {
    if (environment.SeoEnable) {
      Config.SeoTags.keywords = content + ',' + Config.SeoTags.keywords;
      this.meta.updateTag({ name: 'keywords', content: Config.SeoTags.keywords });

    }
  }

  removeMetaKeywordsTag(content) {
    if (environment.SeoEnable) {
      this.meta.removeTag("name='keywords'");
    }
  }


  removeMetaDescriptionTag() {
    if (environment.SeoEnable) {
      this.meta.removeTag("name='description'");
    }
  }
  removeMetaTag(name) {
    if (environment.SeoEnable) {
      this.meta.removeTag("name='" + name + "'");
    }
  }



}

<div *ngIf="formField" [formGroup]="formField">
  <label *ngIf="field && field.colName" for="{{field.colName}}" class="col-form-label mr-4">{{field.label}}</label>
  <div *ngIf="field && field.properties && field.properties.data">
    <ng-multiselect-dropdown [formControlName]="field.colName" class="form-control"
      (onDeSelect)="onItemDeSelect($event)" (onSelect)="onItemSelect($event)" [required]="required"
      name="{{field.colName}}" [settings]="multiSelectConfig" id="{{field.colName}}" class="dropdown-btn"
      [data]="field.properties.data" [ngClass]='setClass()'>
    </ng-multiselect-dropdown>
  </div>
   <!-- required : {{required}}
   submitted : {{validate}} -->
 <!-- {{isError(field.colName)}} -->
 <!-- <div *ngIf="isError('dob')" class="font-14 text-danger">
  <div *ngIf="isRequired('dob')">DOB is Required</div>
  <div *ngIf="isPattern('dob')">Phone Number Pattern Is Invlaid (+123123456)</div>
  <div *ngIf="maxLength('dob')">Maximum {{maxLength('dob').requiredLength}} Characters</div>
    <div *ngIf="minLength('dob')">Minimum {{minLength('dob').requiredLength}} Characters</div>
</div> -->
  <div *ngIf="(validate && isError(field.colName))" class="font-14 text-danger">
    {{field.label}} is Required
  </div>
</div>

<div *ngIf="!formField">
  <label *ngIf="field && field.colName" for="{{field.colName}}" class="col-form-label mr-4">{{field.label}}</label>
  <div *ngIf="field && field.properties && field.properties.data">
    <ng-multiselect-dropdown class="form-control" [(ngModel)]="field.value"
      (onDeSelect)="onItemDeSelect($event)" (onSelect)="onItemSelect($event)" [required]="required"
      name="{{field.colName}}" [settings]="multiSelectConfig" id="{{field.colName}}" class="dropdown-btn"
      [data]="field.properties.data" [ngClass]='setClass()'>
    </ng-multiselect-dropdown>
  </div>
   <!-- required : {{required}}
   submitted : {{validate}} -->
 <!-- {{isError(field.colName)}} -->
</div>
<!-- {{field.properties.data[0] | json}} -->

<!-- {{field.value | json}} -->
<ng-container>
  <div [formGroup]="formField">
    <label for="{{field.colName}}" class="col-form-label">{{field.label}}</label>
    <div class="" *ngIf="field.colName!='country' && field.colName!='state' && field.colName!='city'">
      <select (change)="onChange()" [formControlName]="field.colName" name="{{field.colName}}" class="form-control"
        id="{{field.colName}}">
        <option value="" disabled>Select</option>
        <option *ngFor="let value of field && field.properties.data" value="{{value[field.properties.propertyToBind]}}">
          {{value[field.properties.propertyToShow]}}</option>
      </select>
    </div>
    <!-- [disabled]="field.colName=='country' && (!masterStore.getCountries || masterStore.getCountries.length == 0) ? true:
  field.colName=='state' && (!masterStore.coumputedState || masterStore.coumputedState==0) ? true:
  field.colName=='city' && (!masterStore.coumputedCities || masterStore.coumputedCities==0) ? true:false" -->
    <!-- <ng-container class=""> -->
    <div *ngIf="field.colName=='country'">
      <!-- {{field.value}} -->
      <select (change)="onChange()" [formControlName]="field.colName"  name="{{field.colName}}"  class="form-control"
        id="{{field.colName}}">
        <option value="" disabled>Select</option>
        <!-- <option value="US">United States</option> -->
        <option *ngFor="let value of masterStore.getCountries" value="{{value[field.properties.propertyToBind]}}">
          {{value[field.properties.propertyToShow]}}</option>
      </select>
    </div>
    <div *ngIf="field.colName=='state'">
      <!-- {{field.value}} -->
      <select (change)="onChange()" [formControlName]="field.colName"  name="{{field.colName}}"  class="form-control"
        id="{{field.colName}}">
        <option value="" disabled>Select</option>
        <option *ngFor="let value of masterStore.coumputedState ? masterStore.coumputedState:[]" value="{{value[field.properties.propertyToBind]}}">
          {{value[field.properties.propertyToShow]}}</option>
      </select>
    </div>
    <div *ngIf="field.colName=='city'">
      <!-- {{field.value}} -->
      <select (change)="onChange()" [formControlName]="field.colName"  name="{{field.colName}}"  class="form-control"
        id="{{field.colName}}">
        <option value="" disabled>Select</option>
        <option *ngFor="let value of masterStore.coumputedCities ? masterStore.coumputedCities:[]" value="{{value[field.properties.propertyToBind]}}">
          {{value[field.properties.propertyToShow]}}</option>
      </select>
    </div>
    <div class="text-danger" *ngIf="formField && (formField.controls[field.colName].touched || validate) && !isValid">{{field.label}} is required</div>
    <!-- </ng-container> -->
  </div>
</ng-container>

import { Injectable } from '@angular/core';
// import { UserModel } from '../shared/models/user';
import { observable, action, computed, autorun} from 'mobx';
import { SnakbarModel } from '../common/models/snakbar.model';
import { cloneDeep } from 'lodash';
declare var $: any;
@Injectable({
  providedIn: 'root'
})
export class CommonStore {
  @observable loader: boolean = false;
  //   @observable userModel: UserModel;
  globalAlert: SnakbarModel;
  @observable alertArray: Array<SnakbarModel> = [];
  redirectUrl:any;
  constructor() {
    if (window.localStorage['redirectUrl']) {
      let redUrl = JSON.parse(localStorage["redirectUrl"]);
      this.redirectUrl=Object.assign({}, redUrl);
      
  }
  autorun(() => {
      if (this.redirectUrl) {
          window.localStorage.setItem('redirectUrl', JSON.stringify(this.redirectUrl))
      }
      if (window.localStorage['redirectUrl']) {
        let redUrl = JSON.parse(localStorage["redirectUrl"]);
        this.redirectUrl=Object.assign({}, redUrl);
      }
  })
  }

  @action loaderStart() {
    if (this.loader == true) {
      this.loader = false;
    }
    setTimeout(() => {
      this.loader = true;
    }, 1000);
  }
  @computed get loaderState(){
    return this.loader;
  }

  @action loaderEnd() {
    setTimeout(() => {
      if (this.loader)
        this.loader = false;
    }, 1000);
  }

  @action notifier(notification) {
    this.globalAlert = new SnakbarModel();
    this.globalAlert.id = Math.random().toString();
    this.globalAlert.isOpen = true;
    this.globalAlert.message = notification.message;
    this.globalAlert.case = notification.action;
    this.globalAlert.local=notification.local ? notification.local:false;
    this.alertArray.push(this.globalAlert);
    setTimeout(() => {
      if (this.alertArray && this.alertArray.length > 0) {
        this.alertArray.splice(0, 1)
      }
    }, 4000);
  }
  @action closeNotifier(alert) {
    let index = this.alertArray.findIndex(x => x.id == alert.id);
    if (index > -1) {
      this.alertArray.splice(index, 1);
    }
  }



  @action clearStore() {
    window.localStorage.clear();
    this.redirectUrl=null;
    $('.modal').modal('hide');
  }
  @action resetRedirectUrl()
  {
    this.redirectUrl=null;
    window.localStorage.removeItem('redirectUrl');
  }
  @action setRedirectUrl(url:any)
  {
    this.redirectUrl = cloneDeep(url);
    window.localStorage.setItem('redirectUrl', JSON.stringify(this.redirectUrl));

  }
}

<nav class="sidebar-nav">
  <ul id="sidebarnav">

    <!-- First level menu -->
    <li class="sidebar-item" [class.active]="showMenu === sidebarnavItem.title"
      *ngFor="let sidebarnavItem of sidebarnavItems"
      [routerLinkActive]="sidebarnavItem.submenu.length != 0 ? '' : 'active'">
      <ng-container *ngIf="profileData.userData && ((profileData.userData.role.match(sidebarnavItem.role)) || (sidebarnavItem.role=='common' && profileData.userData.role!='Admin') || (sidebarnavItem.role=='Employer' && profileData.userData.role!='Admin' && profileData.userData.role=='Company_User'))" >
        <div class="nav-small-cap" *ngIf="sidebarnavItem.extralink === true">
          <i [ngClass]="[sidebarnavItem.icon]"></i><span class="hide-menu">{{ sidebarnavItem.title }}</span>
        </div>
        <a class="sidebar-link waves-effect waves-dark"  [routerLink]="
          sidebarnavItem.class === '' ? [sidebarnavItem.path] : null" 
          [ngClass]="[sidebarnavItem.class]" *ngIf="!sidebarnavItem.extralink && !sidebarnavItem.dashboard"
          (click)="addExpandClass(sidebarnavItem.title)"
          [routerLinkActive]="sidebarnavItem.submenu.length != 0 ? '' : 'active'">
          <i [ngClass]="[sidebarnavItem.icon]" *ngIf="sidebarnavItem.title!='Connection Center'"></i>
          <i [ngClass]="[sidebarnavItem.icon]" class="ml-2" *ngIf="sidebarnavItem.title=='Connection Center'"></i>
          <span class="hide-menu text-wrap" *ngIf="sidebarnavItem.title!='Connection Center'">{{ sidebarnavItem.title }}
          </span>
        </a>
        <!-- Second level menu -->
        <ul aria-expanded="false" class="collapse first-level" *ngIf="sidebarnavItem.submenu.length > 0"
          [ngClass]="{ in: showMenu === sidebarnavItem.title }">
          <li class="sidebar-item" *ngFor="let sidebarnavSubItem of sidebarnavItem.submenu"
            [class.active]="showSubMenu === sidebarnavSubItem.title" [routerLinkActive]="
            sidebarnavSubItem.submenu.length > 0 ? '' : 'active'
          ">
            <a class="sidebar-link" [routerLink]="
              sidebarnavSubItem.submenu.length > 0
                ? null
                : [sidebarnavSubItem.path]
            " [routerLinkActive]="
              sidebarnavSubItem.submenu.length > 0 ? '' : 'router-link-active'
            " [ngClass]="[sidebarnavSubItem.class]" *ngIf="!sidebarnavSubItem.extralink"
              (click)="addActiveClass(sidebarnavSubItem.title)">
              <i [ngClass]="[sidebarnavSubItem.icon]"></i>
              <span class="hide-menu">{{ sidebarnavSubItem.title }}</span>
            </a>
            <!-- Third level menu -->
            <ul aria-expanded="false" class="collapse Second-level" *ngIf="sidebarnavSubItem.submenu.length > 0"
              [ngClass]="{ in: showSubMenu === sidebarnavSubItem.title }">
              <li class="sidebar-item" *ngFor="let sidebarnavSubsubItem of sidebarnavSubItem.submenu"
                routerLinkActive="active" [ngClass]="[sidebarnavSubsubItem.class]">
                <a class="sidebar-link" [routerLink]="[sidebarnavSubsubItem.path]"
                  *ngIf="!sidebarnavSubsubItem.extralink" [routerLinkActive]="
                  sidebarnavSubsubItem.submenu.length > 0
                    ? ''
                    : 'router-link-active'">
                  <i [ngClass]="[sidebarnavSubsubItem.icon]"></i>
                  <span class="hide-menu">{{ sidebarnavSubsubItem.title }}</span>
                </a>
              </li>
            </ul>
          </li>
        </ul>
      </ng-container>
    </li>
  </ul>
</nav>

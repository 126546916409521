import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { languages } from 'countries-list';
import { MasterDataStore } from 'src/app/store/masterData.store';
import { MasterDataService } from '../service/masterDataFetcher';
@Component({
  selector: 'app-dynamic-dropdown',
  templateUrl: './dynamic-dropdown.component.html',
  styleUrls: ['./dynamic-dropdown.component.css'],
  changeDetection: ChangeDetectionStrategy.Default
})
export class DynamicDropdownComponent implements OnInit {

  @Input() field: any;
  @Input() formField: FormGroup;
  @Input() validate: any;
  @Output() modelChange: EventEmitter<any> = new EventEmitter<any>();
  timeZoneList: any;
  currentTimeZone: { offset: string; timezone: string; };

  constructor(
    public apiService: MasterDataService,
    public masterStore: MasterDataStore) { }

  ngOnInit(): void {
    if (this.field && this.field.properties && !this.field.properties.frontendMapping) {
      if (!this.field.properties['dataUpdated']) {
        this.apiService.fillData(this.field).subscribe(res => {
          this.field = res;
          this.field.properties['dataUpdated'] = true;
          this.modelChange.emit(this.field);
        })
      }
    }
    else if (this.field && this.field.properties && this.field.properties.frontendMapping && this.field.colName == 'language') {
      let myLanguages = [];
      Object.entries(languages).forEach(
        ([key, value]) => {
          myLanguages.push(value)
        });
      this.field.properties.data = [...myLanguages];
    }
    else if (this.field && this.field.properties && this.field.properties.frontendMapping && this.field.colName == 'timezone') {
      this.field.properties.data = [ 
        {timezone:'Central Time', offset:"Central Time"}, 
        {timezone:'Eastern Time',offset:'Eastern Time'}, 
        {timezone:'Mountain Time',offset:'Mountain Time'}, 
        {timezone:'Pacific Time',offset:'Pacific Time'}]
      
    }
    else if (this.field && this.field.properties && this.field.properties.frontendMapping && this.field.colName == 'country') {
      console.log("selected country", this.formField.value[this.field.colName])
      this.masterStore.selectedCountry = this.formField.value[this.field.colName];
      if (!this.masterStore.selectedCountry) {
        this.masterStore.statesList = [];
      }
    }
    else if (this.field && this.field.properties && this.field.properties.frontendMapping && this.field.colName == 'state') {
      this.masterStore.selectedState = this.field.value;
      if (!this.masterStore.selectedState) {
        this.masterStore.citiesList = [];
      }
    }
    else if (this.field && this.field.properties && this.field.properties.frontendMapping && this.field.colName == 'city') {

      // this.masterStore.selectedCity = this.field.value;
      // this.masterStore.citiesList = this.masterStore.getCities(this.masterStore.selectedCountry, this.masterStore.selectedState);
    }
  }
  get isValid() { return this.formField.controls[this.field.colName].valid; }

  onChange() {
    if (this.field && this.field.properties && this.field.properties.frontendMapping && this.field.colName == 'country') {
      console.log("selected country", this.formField.value[this.field.colName])
      this.masterStore.selectedCountry = this.formField.value[this.field.colName];
      this.masterStore.statesList = this.masterStore.getStates(this.masterStore.selectedCountry);
    }
    else if (this.field && this.field.properties && this.field.properties.frontendMapping && this.field.colName == 'state') {
      this.masterStore.selectedState = this.formField.value[this.field.colName];

      this.masterStore.citiesList = this.masterStore.getCities(this.masterStore.selectedCountry, this.masterStore.selectedState);
    }
    else if (this.field && this.field.properties && this.field.properties.frontendMapping && this.field.colName == 'city') {
      this.masterStore.selectedCity = this.formField.value[this.field.colName];
    }
    this.field.value = this.formField.value[this.field.colName];
    this.modelChange.emit(this.field)
  }

}

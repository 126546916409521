import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { GenericCallsService } from 'src/app/appService/generic-calls.service';
import { ProfileStore } from 'src/app/store/userProfileStore.store';

@Component({
  selector: 'app-my-videos-selector',
  templateUrl: './my-videos-selector.component.html',
  styleUrls: ['./my-videos-selector.component.css']
})
export class MyVideosSelectorComponent implements OnInit {
  myVideos: Array<any> = [];
  selectedVideo: any={fileId:''};
  @Input() View:string='Employee Videos'
  @Output() onFileSelection: EventEmitter<any> = new EventEmitter<any>();
  constructor(
    public genericService: GenericCallsService,
    private profileStore: ProfileStore) { }

  ngOnInit(): void {
    let filter = [{ colName: 'employeeId', value: this.profileStore.userData.id, inputType: 'number', properties: {} }];

    this.genericService.getTableData({ tableName: this.View }, { limit: 5, offset: 0 }, filter).subscribe(res => {
      this.myVideos = res.rows;
    })
  }


  getSignedUrl(key) {
    this.genericService.getSignedUrl(key).subscribe((res: any) => {
      this.selectedVideo['edu_master_file.path'] = res.result.linkToFile;
    })
  }
  onSelect(note) {
    this.selectedVideo=null;
    setTimeout(() => {
      this.selectedVideo = Object.assign({}, note);
    }, 1000);
    this.onFileSelection.emit({ fileId: note['fileId'] })
  }

}

<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title">{{title}}</h4>
  <button *ngIf="showCloseIcon" type="button" class="close" aria-label="Close" (click)="close()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <!-- <pre>{{fieldsArrays | json}}</pre> -->
  <form *ngIf="dataForm" [formGroup]="dataForm" class="row">
    <ng-container *ngFor="let input of fieldsArrays">
      <ng-container *ngIf="input.properties && !input.properties.disabled">

        <div *ngIf="input.inputType=='text'" class="form-group col-lg-4">
          <label for="{{input.colName}}" class=" col-form-label">{{input.label}}</label>
         
          <div class="">
            <input name="{{input.colName}}" [pattern]="pattern[input.pattern] || ''"
              [readOnly]="input.properties && input.properties.readOnly ? true:false" [formControlName]="input.colName"
              type="text" class="form-control" id="{{input.colName}}">
              <span  *ngIf="input.hint" role="button" title="hint" class="hint"> 
                {{input.hint}}
               </span> 
          </div>
          <div [innerHTML]="($errorMessage | async)[input.colName]" class="font-14 text-danger"></div>
          <!-- <div class="text-danger" *ngIf="isError(input.colName) && getValidationError(input.colName)">
            {{getValidationError(input.colName)}}</div> -->
        </div>

        <div *ngIf="input.inputType=='number'" class="form-group col-lg-4">
          <label for="{{input.colName}}" class=" col-form-label">{{input.label}}</label>
          <div class="">
            <input name="{{input.colName}}" [readOnly]="input.properties && input.properties.readOnly ? true:false"
              [formControlName]="input.colName" type="number" class="form-control" id="{{input.colName}}">
          </div>
          <div [innerHTML]="($errorMessage | async)[input.colName]" class="font-14 text-danger"></div>
          <!-- <div class="text-danger" *ngIf="isError(input.colName) && getValidationError(input.colName)">
            {{getValidationError(input.colName)}}</div> -->
        </div>

        <div
          *ngIf="input.inputType=='dropdown' && input.properties && (input.properties.string && input.properties.id && input.properties.toBeFilled)"
          class="form-group col-lg-4">
          <app-dynamic-dropdown [field]="input" [validate]="submitted" [formField]="dataForm"
            (modelChange)="onChangeField($event)">
          </app-dynamic-dropdown>
        </div>
        <div
          *ngIf="input.inputType=='dropdown' && input.properties && (input.properties.string && input.properties.id && !input.properties.toBeFilled)"
          class="form-group col-lg-4">
          <label for="{{input.colName}}" class="col-form-label">{{input.label}}</label>
          <div class="">
            <select name="{{input.colName}}" [formControlName]="input.colName" class="form-control"
              id="{{input.colName}}">
              <option value="">Select</option>
              <option *ngFor="let value of input.properties.data" value="{{value[input.properties.propertyToBind]}}">
                {{value[input.properties.propertyToShow]}}</option>
            </select>
          </div>
          <div [innerHTML]="($errorMessage | async)[input.colName]" class="font-14 text-danger"></div>
          <!-- <div class="text-danger" *ngIf="isError(input.colName) && getValidationError(input.colName)">
            {{getValidationError(input.colName)}}</div> -->
        </div>

        <div *ngIf="input.inputType=='textarea'" class="form-group col-lg-12">
          <label for="{{input.colName}}" class=" col-form-label">{{input.label}}</label>
          <div class="">
            <textarea name="{{input.colName}}"              
              [formControlName]="input.colName"  class="form-control" id="{{input.colName}}"></textarea>
          </div>
          <div [innerHTML]="($errorMessage | async)[input.colName]" class="font-14 text-danger"></div>
          <!-- <div class="text-danger" *ngIf="isError(input.colName) && getValidationError(input.colName)">
            {{getValidationError(input.colName)}}</div> -->
        </div>

        <div *ngIf="(input.inputType=='dateOnly' || input.inputType=='date')" class="form-group col-lg-4">
          <!-- =>{{getMinDate(input) | json}} -->
          <label for="{{input.colName}}" class="col-form-label">{{input.label}}</label>
          <div class="" *ngIf="input.properties && !input.properties.dateTime">
            <input [min]="getMinDate(input)" [max]="getMaxDate(input)" name="{{input.colName}}"
              [formControlName]="input.colName" (change)="changeDateOnly(input.value)" type="date" class="form-control"
              id="{{input.colName}}">
          </div>

          <div class="" *ngIf="input.properties && input.properties.dateTime">
            <input [min]="getMinDate(input)" [max]="getMaxDate(input)" name="{{input.colName}}" [formControlName]="input.colName"
              (change)="changeDateOnly(input.value)" type="datetime-local" class="form-control" id="{{input.colName}}">
          </div>

          <div [innerHTML]="($errorMessage | async)[input.colName]" class="font-14 text-danger"></div>
          <!-- <div class="text-danger" *ngIf="isError(input.colName) && getValidationError(input.colName)">
            {{getValidationError(input.colName)}}</div> -->
        </div>

        <div *ngIf="(input.inputType=='dateTimeRange' || input.inputType=='dateRange')" class="form-group col-lg-6">
          <label for="{{input.colName}}" class="col-form-label">{{input.label}}</label>
          <input class="form-control" name="{{input.colName}}" id="{{input.colName}}" ngxDaterangepickerMd
            [closeOnAutoApply]="true" [autoApply]="false" [linkedCalendars]="true"
            [formControlName]="input.colName" [timePicker]="input.inputType=='dateTimeRange' ? true:false"
            (datesUpdated)="choosedDateTime($event)">

            <div [innerHTML]="($errorMessage | async)[input.colName]" class="font-14 text-danger"></div>
          <!-- <div class="text-danger" *ngIf="isError(input.colName) && getValidationError(input.colName)">
            {{getValidationError(input.colName)}}</div> -->
        </div>

        <div *ngIf="input.inputType=='boolean'" class="form-group col-lg-4">
          <label for="{{input.colName}}" class="col-form-label mr-4">{{input.label}}</label>
          <input name="{{input.colName}}" type="checkbox" [checked]="input.value" id="{{input.colName}}"
            [formControlName]="input.colName" class="form-check-input" size="small">
            <div [innerHTML]="($errorMessage | async)[input.colName]" class="font-14 text-danger"></div>
          <!-- <div class="text-danger" *ngIf="isError(input.colName) && getValidationError(input.colName)">
            {{getValidationError(input.colName)}}</div> -->
        </div>

        <div *ngIf="input.inputType=='file'" class="form-group col-lg-4">
          <dynamic-file-selector [validate]="submitted" [formField]="dataForm" (onFileSelection)="onChangeField($event)" [field]="input" [fileTypes]="input.properties.extensions"></dynamic-file-selector>
        </div>

      </ng-container>
    </ng-container>

  </form>
  <div class="modal-footer">
    <button type="button" [disabled]="loading" type="submit" class="btn btn-outline-dark" (click)="saveEvent()">{{buttonTitle}}</button>
  </div>

</div>

<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title">Payment Modal</h4>
  <button type="button" class="close" aria-label="Close" (click)="onClose()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <div class="row">
    <div class="col-xs-12 col-md-12">
      <div class="panel panel-default">
        <div class="panel-heading">
          <h3 class="panel-title">
            Payment Details
          </h3>
          <div class="checkbox pull-right">
            <label>
              <b>Plan :</b>{{plan && plan.name}}
              <br>
              <b>Price:</b> $ {{plan && plan.price}} {{plan && plan.name=='Developent Training' ? '': ' / Year'}}
            </label>
          </div>
        </div>
        <div class="panel-body">

          <form role="form" [formGroup]="paymentForm">
            <div class="form-group">
              <label for="cardNumber">
                CARD NUMBER</label>
              <div class="input-group">
                <input required formControlName="card" type="text" class="form-control" id="cardNumber"
                  placeholder="Valid Card Number" required autofocus />
              </div>
              <div *ngIf="isError('card')" class="font-14 text-danger">
                <div *ngIf="isRequired('card')">Card Number is Required</div>
                <div *ngIf="!maxLength('card') && isPattern('card')">Card Pattern Is Invlaid</div>
                <div *ngIf="maxLength('card')">Maximum {{maxLength('card').requiredLength}} Digits</div>
                <div *ngIf="minLength('card')">Minimum {{minLength('card').requiredLength}} Digits</div>
              </div>
            </div>
            <div class="row">
              <div class="col-xs-7 col-md-7">
                <div class="form-group">
                  <label for="expityMonth">
                    EXPIRY DATE (MM/YY)</label>
                  <div class="col-xs-6 col-lg-6 pl-ziro">
                    <input required formControlName="expiry" type="text" class="form-control" id="expityMonth"
                      placeholder="MM/YY" required />
                  </div>
                  <div *ngIf="isError('expiry')" class="font-14 text-danger">
                    <div *ngIf="isRequired('expiry')">Expiry is Required</div>
                    <div *ngIf="!maxLength('expiry') && isPattern('expiry')">Card Pattern Is Invlaid</div>
                    <div *ngIf="maxLength('expiry')">Maximum {{maxLength('expiry').requiredLength}} Digits</div>
                    <div *ngIf="minLength('expiry')">Minimum {{minLength('expiry').requiredLength}} Digits</div>
                  </div>
                </div>
              </div>
              <div class="col-xs-5 col-md-5 pull-right">
                <div class="form-group">
                  <label for="cvCode">
                    CV CODE (***)</label>
                  <input required formControlName="cvv" type="text" class="form-control" id="cvCode"
                    placeholder="CVV" required />
                </div>
                <div *ngIf="isError('cvv')" class="font-14 text-danger">
                    <div *ngIf="isRequired('cvv')">CVV is Required</div>
                    <div *ngIf="!maxLength('cvv') && isPattern('cvv')">CVV Pattern Is Invlaid</div>
                    <div *ngIf="maxLength('cvv')">Maximum {{maxLength('cvv').requiredLength}} Digits</div>
                    <div *ngIf="minLength('cvv')">Minimum {{minLength('cvv').requiredLength}} Digits</div>
                  </div>
              </div>
            </div>
          </form>
        </div>
      </div>
      <br />
      <a class="btn btn-success btn-lg btn-block" (click)="payment()" role="button">Pay</a>
    </div>
  </div>
</div>

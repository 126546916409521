import { Component, EventEmitter,ElementRef, OnInit, Output, ViewChild, Input } from '@angular/core';
import { MediaUtilService } from 'src/app/common/mediaUtil';

@Component({
  selector: 'file-selector',
  templateUrl: './file-selector.component.html',
  styleUrls: ['./file-selector.component.css']
})
export class FileSelectorComponent implements OnInit {
  @ViewChild("imageSelector") imageSelector: ElementRef;

  @Input() title:string='Profile Picture';
  @Input() fileTypes:string='image/png, image/jpg, image/jpeg';
  @Output() onFileSelection: EventEmitter<any>=new EventEmitter<any>();
  mediaObj = { name: '', type: '', src: null };
  error: string;
  constructor(public mediaAssetUtil:MediaUtilService) { }

  ngOnInit(): void {
  }

  fileSelector(){
    let fileChooser: HTMLInputElement = document.getElementById(
      "fileToUpload"
    ) as HTMLInputElement;
    fileChooser.click();
  }

  fileRead(event) {
 
    if((event.target.files[0]  && this.fileTypes.match(event.target.files[0].type.toLowerCase())) || this.fileTypes.includes('audio')){

      this.mediaObj.name = event.target.files[0].name;
      this.mediaObj.type = event.target.files[0].type;
      const imageFileBase64 = { imagename: '', src: '' };
      imageFileBase64.imagename = event.target.files[0].name;
      this.mediaAssetUtil.fileToBase64(event.target.files[0]).then(file => {
        this.mediaObj.src = file;
        this.onFileSelection.emit(event.target.files[0]);
      })
    }else{
      this.error= this.mediaObj.type.includes('audio') ? 'Please select valid audio file':'Please select one image with .png, jpg or jpeg format for Profile Picture';
      setTimeout(() => {
        this.error=null
      }, 3000);
    }
  }
  removePicture(){
    this.imageSelector.nativeElement.value=null;
    this.mediaObj={name:'',src:null,type:null};
    this.onFileSelection.emit(null);

  }
}

import { Component, OnInit } from '@angular/core';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { ViewChild } from '@angular/core';
import { GenericCallsService } from 'src/app/appService/generic-calls.service';
import { ProfileStore } from 'src/app/store/userProfileStore.store';
import { Config } from 'src/app/common/config/config';
@Component({
  selector: 'shared-plans-list',
  templateUrl: './plans-list.component.html',
  styleUrls: ['./plans-list.component.css']
})
export class PlansListComponent implements OnInit {
  @ViewChild('popupForm', { static: false }) private popupForm;
  config=Config.AppConfig
  closeResult: string;
  plane: { name: any; price: any; };
  selectedPlane: { name: any; price: any; };

  constructor(
    private modalService: NgbModal,
    public apiService:GenericCallsService,
    public profileStore:ProfileStore
  ) { }

  ngOnInit(): void {
  }
  openPayment(plan, price){
    this.selectedPlane={name:plan,price:price};
    this.openFileSelectorModel(this.popupForm);
  }
  
  customPaymentRequest(plan){
    this.apiService.onCustomPayment( {
      plan:plan
    }).subscribe(res=>{
      this.onClose('close');
    })

  }
  openFileSelectorModel(content1: any) {
    this.modalService.open(content1, { ariaLabelledBy: 'modal-basic-title', size: 'md' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
  private getDismissReason(reason: ModalDismissReasons): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  onClose(event){
    this.selectedPlane=null;
    this.modalService.dismissAll()

  }

  ifPlanSelected(planName){
    if(planName!='Silver'){
      return this.profileStore.userData && this.profileStore.userData.role.match(planName);
    }else{
      return this.profileStore.userData && (this.profileStore.userData?.role=='Employer_PD' || this.profileStore.userData?.role=='Employer')
    }
  }

  onPayment(data){
    let body={
      card:data.card,
      cvv:data.cvv,
      expiry:data.expiry,
      plan:this.selectedPlane.name,
      type:"Plan"
    }
    this.apiService.onPayment(body).subscribe(res=>{
      this.profileStore.setUser(res.results);
      location.reload()
    })
  }
}

import { Injectable, isDevMode } from '@angular/core';
import { HttpHandler, HttpInterceptor, HttpEvent, HttpRequest, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { tap, catchError, map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { CommonStore } from '../store/common.store';
import { ProfileStore } from '../store/userProfileStore.store';
// import { requests } from '../common/config/requests';
import { DisableLoaderCalls, DisableNotification, RequestsWithoutAccesstoken } from './bypassRequests';
// import { DisableLoaderCalls } from './callsConfig';

@Injectable({
    providedIn: "root"
})
export class AuthInterceptor implements HttpInterceptor {
    constructor(
        public commonStore: CommonStore,
        public router: Router,
        public profileStore: ProfileStore
    ) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        let ifDisableLoader = DisableLoaderCalls.some(x => request.urlWithParams.match(x));
        if (!ifDisableLoader) { this.commonStore.loaderStart(); }
        // this.commonStore.loaderStart();
        if ((RequestsWithoutAccesstoken.some(x => request.urlWithParams.match(x)))) {

            return next.handle(request).pipe(tap((evt: any) => {
                // if (evt instanceof HttpResponse) {
                    // this.clearConsole()
                if (evt.body && evt.body.message) {
                    this.commonStore.loaderEnd()
                }
                else{
                    this.commonStore.loaderEnd();
                }
                // }
            }),
                catchError(error => {
                    this.commonStore.loaderEnd();
                    this.commonStore.notifier({ message:error.error.message || error.message  || 'Error Occured', action: 'error' })
                    if (error.statusText == 'Unknown Error') {
                        this.router.navigateByUrl('server-down');
                    }
                    console.log("error http call",error)
                    throw (error)
                }));
        } else {
            const headers = request.headers
                .set('Authorization', this.profileStore.userToken ? this.profileStore.userToken : null)
                // .set('id', this.profileStore.userData ? this.profileStore.userData.id.toString() : null);
            const authReq = request.clone({ headers: headers });
            return next.handle(authReq).pipe(tap((evt: any) => {
                // if (evt instanceof HttpResponse) {
                if (evt.body && evt.body.message) {
                    
                    this.commonStore.loaderEnd()
                }
                // }
            }),
                catchError(error => {
                    this.commonStore.loaderEnd();
                    let ifDisableLoader = DisableNotification.some(x => request.urlWithParams.match(x));
                    if (!ifDisableLoader) { 
                        this.commonStore.notifier({ message: error.statusText == 'Forbidden' ? 'Your session is expired. Please login again':error.error?.message || error.message || 'Error Occured', action: 'error' }) 
                    }
                    if (error.statusText == 'Unknown Error') {
                        this.router.navigateByUrl('server-down');
                    }
                    else if (error.status==403 || error.statusText == 'Forbidden' || error.error?.message=='Invalid Token' || error.message=='Invalid Token') {
                        this.profileStore.reset();
                        this.router.navigateByUrl('/');
                    }
                    console.log("error auth http call", error)
                    throw (error)
                }));
        }
    

        // }
        // else {
        //     const headers = request.headers
        //         .set('Authorization', this.profileStore.userModel.sessionToken)
        //         .set('userId', this.profileStore.userModel.userId.toString());
        //     const authReq = request.clone({ headers: headers });
        //     let ifDisableLoader = DisableLoaderCalls.some(x => request.urlWithParams.match(x));
        //     if (!ifDisableLoader) { this.profileStore.loaderStart(); }
        //     // this.profileStore.loaderStart();
        //     return next.handle(authReq).pipe(tap(evt => {
        //         if (evt instanceof HttpResponse) {
        //             if (evt.body) {
        //                 if (evt.body && evt.body.message && evt.body.message == "Session does not exist") {
        //                     this.router.navigateByUrl("/auth")
        //                 }
        //                 this.profileStore.loaderEnd();
        //             }
        //         }
        //     }),
        //         catchError(error => {
        //             this.profileStore.loaderEnd();
        //             if (error.error.message == 'Token is expired' || error.error.message == "You are not authorized!" || error.error.message == "Token is invalid") {
        //                 this.router.navigateByUrl('/');
        //                 this.profileStore.clearStore();
        //             }
        //             if (error.statusText == 'Unknown Error') {
        //                 this.router.navigateByUrl('server-down');
        //             }
        //             this.profileStore.notifier({ message: error.error && error.error.message ? error.error.message : "Server is not responding", action: 'error' })
        //             throw (error)
        //         }));
        // }
    }
    clearConsole() { 
        if(window.console) {
           console.clear();
        }
    }
}
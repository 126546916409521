<section *ngIf="config.payment" id="pricing" class="pricing pt-0">
  <div class="container" data-aos="fade-up">

    <div class="section-title">
      <h3>Packages</h3>
      <p>Following are details of the packages that employer will be able to choose from</p>
    </div>

    <div class="row">

      <div class="col-lg-4 col-md-6 mt-4 mt-md-0" data-aos="fade-up" data-aos-delay="200">
        <div class="box featured">
          <div class="float-right" *ngIf="ifPlanSelected('Silver')">
            <i class="text-white mdi-18px mdi mdi-checkbox-marked-circle"></i>
          </div>
          <h3>Edu Opening Free</h3>
          <h4>Free<span> / Year</span></h4>
          <ul>
            <li>Create Profile</li>
            <li >Post Open Positions </li>
            <li >View Candidate That Apply</li>
            <li >Media Page Access</li>
            <li class="na">Custom Video Questions Direct Message </li>
            <li class="na">Edu Openings will search/recruit candidates for organizations</li>
          </ul>
          <div class="btn-wrap">
            <!-- <a href="#" class="btn-buy">Select</a> -->
          </div>
        </div>
      </div>
<!-- removed by client -->
      <!-- <div class="col-lg-4 col-md-6 mt-4 mt-lg-0" data-aos="fade-up" data-aos-delay="300">
        <div class="box red-box">
          <div class="float-right" *ngIf="ifPlanSelected('Gold')">
            <i class="text-white mdi-18px mdi mdi-checkbox-marked-circle"></i>
          </div>
          <h3>Edu Opening Gold</h3>
          <h4><sup>$</sup>1500<span> / Year</span></h4>
          <ul>
            <li>View Employee Profiles</li>
            <li>View video responses per candidate. This feature will be unlimited with additional
              $1000/year </li>
            <li>Search capabilities</li>
            <li>Access to see who is looking at the organization </li>
            <li>Notifications of new resumes and videos that match their interests.</li>
          </ul>
          <div class="btn-wrap">
            <a role="button" (click)="openPayment('Gold', 1500)" class="btn-buy">Select</a>
          </div>
        </div>
      </div> -->

      <div class="col-lg-4 col-md-6 mt-4 mt-lg-0" data-aos="fade-up" data-aos-delay="400">
        <div class="box gold-box">
          <div class="float-right" *ngIf="ifPlanSelected('Vibranium')">
            <i class="text-white mdi-18px mdi mdi-checkbox-marked-circle"></i>
          </div>
          <h3>Edu Opening Vibranium</h3>
          <h4><sup>$</sup>5000<span> / Year</span></h4>
          <ul>
            <li>View Employee Profiles</li>
            <li>View video responses per candidate </li>
            <li>Search capabilities</li>
            <li>Access to see who is looking at the organization </li>
            <li>Custom Video Questions Direct Message </li>
            <li>Edu Openings will search/recruit candidates for organizations</li>
          </ul>
          <div class="btn-wrap">
            <a role="button" (click)="openPayment('Vibranium', 5000)" class="btn-buy">Select</a><br>
          <span ><p>$1000 off if you post your RFPs and RFQs on Edu Openings</p></span>
          </div>
        </div>
      </div>

      <div class="col-lg-4 col-md-6 mt-4 mt-lg-0" data-aos="fade-up" data-aos-delay="400">
        <div class="box red-box">
          <h3>Edu Opening Custom</h3>
          <ul>
            <li>For a custom license, contact us@Eduopenings.com</li>
          </ul>
           <div class="btn-wrap">
          </div> 
        </div>
      </div>


    </div>

  </div>
</section><!-- End Pricing Section -->
<ng-template #popupForm let-modal>
  
<app-payment-definition [plan]="selectedPlane" (close)="onClose($event)" (onPayment)="onPayment($event)"></app-payment-definition>
<div class="text-center"><label>OR</label></div>

<div class="modal-footer">
  <button class="btn btn-block btn-primary" (click)="customPaymentRequest('Vibranium')">Request Custom Payment ($1000 Off)</button>
</div>
</ng-template>

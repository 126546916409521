import {  ApplicationRef, Component} from '@angular/core';
import { MetaSeoService } from 'src/app/appService/meta-seo.service';
// import { SwUpdate } from '@angular/service-worker';
// import { concat,interval } from 'rxjs';
// import { first } from 'rxjs/operators';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'app';
  constructor(
    private metaService:MetaSeoService
    // updates: SwUpdate
    ) {
    
  //   const appIsStable$ = appRef.isStable.pipe(first(isStable => isStable === true));
  //   const everySixHours$ = interval(60* 1000);
  //   const everySixHoursOnceAppIsStable$ = concat(appIsStable$, everySixHours$);
  //   everySixHoursOnceAppIsStable$.subscribe(() =>{
  //     updates.checkForUpdate().then(()=>{
        
  //       updates.available.subscribe(event => {
  //         console.log("refreshing2");
  //            console.log('current version is', event.current);
  //         console.log('available version is', event.available);
  //           document.location.reload();
  //       });
  //       }
  //     )
  //     console.log("che1wqwcking");
  //   } );
  //   console.log('updates',updates);
  //   updates.activated.subscribe(event => {
  //     console.log('old version was', event.previous);
  //     console.log('new version is', event.current);
  //   });
  }
  // abc(xyz) {

  // }
}

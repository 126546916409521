export const Views = [
    // Employee Jobs
    { showFilter: true, title: 'Brief Statement', flag: 'Brief Statement' },
    { showFilter: true, title: 'Profile', flag: 'Profile' },
    { showFilter: true, title: 'Educational Details', flag: 'Educational Details', tableName: 'edu_education' },
    { showFilter: true, title: 'Employment History', flag: 'Employment History' },
    { showFilter: true, title: 'Expertise', flag: 'Expertise' },
    { showFilter: true, title: 'Interests', flag: 'Interests' },
    { showFilter: true, title: 'Services Provided', flag: 'Expertise' },
    { showFilter: true, title: 'Work Samples', flag: 'Work Samples' },
    { showFilter: true, title: 'Certifications', flag: 'Certifications' },
    { showFilter: true, title: 'References', flag: 'References' },

    { showFilter: true, title: 'Host Development', flag: 'Host Professional Development', addButton: true, addButtonTitle: 'Add Development', createView: 'auth/host/h-create', view: 'auth/host/h-view', editView: 'auth/host/h-update' },
    { showFilter: true, title: 'Create Host Development', saveButton: true, saveBtnTitle: 'Submit', flag: 'Host Professional Development' },
    { showFilter: true, title: 'Update Host Development', saveButton: true, saveBtnTitle: 'Submit', flag: 'Host Professional Development' },
    { showFilter: true, title: 'View Host Development', flag: 'View Development Details' ,file: true},
    { showFilter: true, title: 'Messages', flag: 'Custom Messages', role: 'Service_Provider' },
    { showFilter: true, title: 'Messages', flag: 'Custom Messages', role: 'Employee' },
    { showFilter: false, title: 'Messages', flag: 'Custom Question Videos', role: 'Employer' },
    { showFilter: true, title: 'Upcoming Development', flag: 'Upcoming Professional Development', addButton: false, view: 'auth/upcoming/u-view' },
    { showFilter: true, title: 'Applied Jobs', flag: 'Applied Jobs', addButton: false, view: 'auth/applied/a-job-details' },
    { showFilter: true, title: 'Upcoming Interviews', flag: 'Employee Upcoming Interviews', addButton: false, view: 'auth/interviews/e-interview' },
    { showFilter: true, title: 'Registered Development', flag: 'Registered Development', addButton: false, view: 'auth/applied/a-training-details' },
    { showFilter: false, title: 'View Participants', flag: 'View Participants', addButton: false },
    { showFilter: true, title: 'Favorite Jobs', flag: 'Favourite Jobs', view: 'auth/saved/s-jobs' },
    { showFilter: true, title: 'Review Employers', flag: 'Review Employers', addButton: false, view: 'auth/service/service-view' },
    { title: 'Applied Job Details', flag: 'View Applied Job', file: true },
    { title: 'View Favorite Job', flag: 'View Favourite Job', file: false },
    { title: 'Screener', flag: 'Employee Screen Videos', file: false },
    { title: 'Employer Screener', flag: 'Employer Screen Videos', file: false },
    { title: 'Upload Video', flag: 'Employee Videos', file: false, role: 'Employee' },
    { title: 'Upload Video', flag: 'Employer Videos', file: false, role: 'Employer' },
    { title: 'Registered Development Details', flag: 'View Registered Development Details' },
    { title: 'View Development Details', flag: 'View Development Details' },
    { title: 'View Interview Details', flag: 'View Interview Employee' },

    // Employer views
    // { showFilter: true, title: 'Post Jobs', flag: 'Job Search', addButton: true, addButtonTitle: 'Post Job', createView: 'jobs/create' },
    { title: 'View Interview Employee', flag: 'View Interview Employee', file: false },
    { showFilter: false, title: 'Profile Views', flag: 'Profile Views', addButton: false },
    { showFilter: true, title: 'Resume Updates', flag: 'Resume Updates', addButton: false },
    { showFilter: true, title: 'View Applicants', flag: 'Review Applicants', addButton: false },
    { showFilter: true, title: 'Custom Question Videos', flag: 'Custom Question Videos', addButton: false },
    { showFilter: true, title: 'Employee Interviews', flag: 'Employee Interviews', addButton: false, view: 'auth/jobs/interview-view', editView: 'auth/jobs/interview-edit' },
    { showFilter: true, title: 'Create Job', saveButton: true, saveBtnTitle: 'Submit', flag: 'Post Job', addButton: false },
    // { showFilter: true, title: 'Review Employees', flag: 'Review Employees', addButton: false },
    { showFilter: true, title: 'Request', flag: 'Post Service', addButton: true, addButtonTitle: 'Post Service', createView: 'auth/service/createService', view: 'auth/service/service-view', editView: 'auth/service/service-update' },
    { showFilter: true, title: 'Post Service', saveButton: true, saveBtnTitle: 'Submit', flag: 'Post Service', addButton: false },
    { showFilter: true, title: 'Post Jobs', flag: 'Post Job', addButton: true, addButtonTitle: 'Post Job', createView: 'auth/jobs/create', view: 'auth/jobs/job-view', editView: 'auth/jobs/job-update' },
    { showFilter: true, title: 'Update Job', flag: 'Post Job', saveButton: true, saveBtnTitle: 'Submit', addButton: false },
    { showFilter: true, title: 'Update Service', saveButton: true, saveBtnTitle: 'Submit', flag: 'Post Service', addButton: false },
    { showFilter: true, title: 'Job View', flag: 'Job View', addButton: false, file: true },
    { showFilter: true, title: 'Review Employees', flag: 'Review Employees', addButton: false, view: 'auth/service/service-view' },
    { showFilter: true, title: 'Featured Employees', flag: 'Featured Employees', addButton: false },
    { showFilter: true, title: 'Review Service Provider', flag: 'Review Service Provider', addButton: false, view: 'auth/service/service-view' },
    { showFilter: true, title: 'View Service Applicants', flag: 'Review Service Provider Applicants', addButton: false },
    { showFilter: false, title: 'Update Interview', flag: 'Schedule Interview', addButton: false },
    { showFilter: true, title: 'Service Provider Interviews', flag: 'Service Provider Interviews', addButton: false, view: 'auth/service/sp-interview', editView: 'auth/service/interview-edit' },
    { showFilter: false, title: 'View Interview Service Provider', flag: 'View Interview Service Provider', addButton: false },

    // Freelancer
    { showFilter: true, title: 'Service Upcoming Interviews', flag: 'Service_Provider Upcoming Interviews', addButton: false, view: 'auth/interviews/sp-interview' },
    { showFilter: true, title: 'Search Service', flag: 'Service Search', addButton: false, view: 'auth/service/service-view' },
    { showFilter: true, title: 'Applied Services', flag: 'Applied Services', addButton: false, view: 'auth/service/a-service-details' },
    { showFilter: true, title: 'Favorite Services', flag: 'Favourite Services', addButton: false, view: 'auth/service/f-service' },
    { title: 'View Applied Service', flag: 'View Applied Service' },
    { title: 'View Favorite Service', flag: 'View Favourite Service' },
    { title: 'Service View', flag: 'Service View', file: true },
    { title: 'About Business', flag: 'About Your Business', file: false },
    { title: 'Service Provider Interview Details', flag: 'View Interview Service Provider', file: false },
    // { showFilter: true, title: 'Services', flag: 'Services', addButton: false, view: 'jobs/view' },

    // Common Views
    { showFilter: true, title: 'Jobs Search', flag: 'Job Search', addButton: false, view: 'auth/jobs/job-view' },
    { showFilter: false, title: 'Media Library', flag: 'Media', addButton: true ,addButtonType: 'popup', addButtonTitle: 'Upload Video' },
    // Admin views
    { showFilter: true, title: 'Manage Employees',customCsv:true, flag: 'Manage Employees', addButton: false, view: 'auth/m-e/view-employee', exports: ['Active Employee', 'Inactive Employee', 'Rejected Employee'] },
    { showFilter: false, title: 'Manage Training Feature', flag: 'Grant PD', addButton: false },
    { showFilter: true, title: 'Manage Employers', flag: 'Manage Employers', addButton: false, view: 'auth/m-e/view-employer', exports: ['Active Employers', 'Inactive Employers', 'Rejected Employers'] },
    { showFilter: false, title: 'Manual Payments', flag: 'Manual Payments', addButton: false },
    // { showFilter: true, title: 'Settings', flag: 'Settings', addButton: false, view: 'm-e/view-employer' },
    { showFilter: true, title: 'Manage Service Providers', flag: 'Manage Service Providers', addButton: false, view: 'auth/m-e/view-service-provider', exports: ['Active Service Provider', 'Inactive Service Provider', 'Rejected Service Provider'] },
    { showFilter: true, title: 'Manage Development Trainings', flag: 'Manage Development Trainings', addButton: false, view: 'auth/manage/u-view', exports: ['Submitted Trainings', 'Accepted Trainings', 'Rejected Trainings'] },
    { showFilter: true, title: 'Manage Jobs', flag: 'Manage Jobs', addButton: false, view: 'auth/jobs/job-view', exports: ['Submitted Jobs', 'Accepted Jobs', 'Rejected Jobs'],editView: 'auth/jobs/job-update' },
    { showFilter: true, title: 'Manage Services', flag: 'Manage Services', addButton: false, exports: ['Submitted Services', 'Accepted Services', 'Rejected Services'], view: 'auth/manage/service-view' },
    { showFilter: false, title: 'Manage Media Library', flag: 'Media', addButton: true, addButtonType: 'popup', addButtonTitle: 'Upload Video', view: 'auth/service/service-view' },
    { showFilter: false, title: 'Manage Video Uploads', flag: 'Manage Video Uploads', addButton: false },
    // manage screener videos
    { showFilter: false, title: 'Manage Screener Uploads', flag: 'Manage Screener Uploads', addButton: false },
    { showFilter: true, saveButton: true, saveBtnTitle: 'Save', title: 'Master Expertise', flag: 'Master Expertise', addButton: true, addButtonTitle: 'Add Expertise', createView: 'auth/settings/tab-settings/add-expertise', editView: 'auth/settings/tab-settings/edit-expertise' },
    { showFilter: true, title: 'Dashboard', flag: 'Dashboard', addButton: false },
    { showFilter: true, title: 'Messages', flag: 'Custom Messages', addButton: false },

    { title: 'Add Expertise', flag: 'Master Expertise' },
    { title: 'Post Notification', flag: 'Post Notification' },
    { title: 'Edit Expertise', flag: 'Master Expertise' },
    // search landing page
    { showFilter: true, title: 'Jobs', flag: 'Jobs', addButton: false, view: 'search/job-view' },
    { showFilter: true, title: 'Employees', flag: 'Employees', addButton: false, view: 'search/viewEmployeeDetails' },
    { showFilter: true, title: 'Employer', flag: 'Employer', addButton: false, view: 'search/viewEmployerDetails' },
    { showFilter: true, title: 'Service Provider', flag: 'Service Provider', addButton: false, view: 'search/viewEmployerDetails' },
    { showFilter: true, title: 'Services', flag: 'Services', addButton: false, view: 'search/service-view' },
    { showFilter: true, title: 'Development Trainings', flag: 'Development Trainings', addButton: false, view: 'search/training-view' },
    { showFilter: true, title: 'Register Org Users', flag: 'List Users', addButton: false },
    { showFilter: false, title: 'Review Resume', flag: 'Review Resume', addButton: false }
]

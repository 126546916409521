<ng-container *ngIf="config.flag.match('Development') && !profileStore.userData?.role.match('Company_User')">
  <div *ngIf="!requestStatus || (requestStatus && requestStatus.requestSent!=1 && requestStatus.requestSent!=3  && requestStatus.requestSent!=4)">
    Dear distinguished user to access this screen you need admin's permission
    <br>
    <p *ngIf="requestStatus">
        <label>Request Status: </label>{{requestStatus && requestStatus.status.description}}
    </p>
    <button class="btn btn-primary" (click)="sendRequest('')">Send Request</button>
  </div>
  <div *ngIf="requestStatus && requestStatus.requestSent==1">
    Dear distinguished user. Please wait! While your request is being processed
    <br>
    <label>Request Status : {{requestStatus && requestStatus.status.description}}</label>
  </div>
  <div *ngIf="requestStatus && (requestStatus.requestSent==3 || requestStatus.requestSent==4)">
    Congrats! Your request to avail this feature is approved by admin
    <br>
    <button class="btn btn-primary" (click)="payNow()">Pay Now</button>
  </div>
</ng-container>

<ng-container *ngIf="this.config.flag.match('Development') && profileStore.userData?.role.match('Company_User')">
  <div *ngIf="!requestStatus || (requestStatus && requestStatus.requestSent!=1 && requestStatus.requestSent!=3 && requestStatus.requestSent!=4)">
    Dear user this feature is not availed by your company yet.
    <br>
  </div>
  <div *ngIf="requestStatus && requestStatus.requestSent">
    {{requestStatus.status.description}}
    <br>
  </div>
</ng-container>
<ng-container *ngIf="!config.flag.match('Development') && !profileStore.userData?.role.match('Company_User')">
  <div class="mb-2">
      Dear user this feature is not available in current plan
    <br>
    <shared-plans-list></shared-plans-list>
  </div>
</ng-container>
<ng-container *ngIf="!config.flag.match('Development') && profileStore.userData?.role.match('Company_User')">
  <div class="mb-2">
      Dear user you don't have this privilege by your company.
    <br>
  </div>
</ng-container>

<ng-template #popupForm let-modal>
  <app-payment-definition [plan]="{'name':'Development Training',price:250}" (close)="closeBtnClick($event)" (onPayment)="onPayment($event)">
  </app-payment-definition>
</ng-template>

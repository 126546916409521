<div class="error-box">
    <div class="error-body text-center">
      <label for="email">Enter Email to unsubscribe</label><br>
      <input #emailInput type="email" id="email" name="email" required><br>
      <a class="btn btn-danger btn-rounded waves-effect waves-light m-b-40 mt-2" (click)="onUnsubscribe()">Unsubscribe</a>
      <!-- <div *ngIf="showMessage"> -->
        <p [hidden]="!showMessage">You have been unsubscribed from EduOpenings successfully. You will be redirected to Home screen shortly.</p>
      <!-- </div> -->
    </div>
  </div>
  <!-- <div class="error-box" *ngIf="showMessage">
  </div> -->
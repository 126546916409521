<div class="row">
  <div class="col-md-3 bg-white overflow-auto fixed-left-part show-panel">

    <div class="position-relative">
      <div class="p-l-15 p-r-25 py-3 d-flex align-items-center border-bottom">
      </div>
      <div>
        <div class="notes-list" *ngIf="myVideos.length > 0; else elseBlock">
          <ng-container *ngFor='let note of myVideos '>
           
            <div *ngIf="note['master_status.description']=='Accepted'"  class="notes-item" role="button" (click)="onSelect(note);"
              [ngClass]="note['fileId'] == selectedVideo['fileId'] ? 'selected':''">
              <div class="d-flex py-3 border-bottom pr-3 position-relative">
                <div>
                  <span class="note-title text-truncate">{{note['edu_master_file.title']}}</span>
                  <br>
                  <small class="note-time">{{note['edu_master_file.date'] | date:'MM-dd-yyyy'}}</small>
                </div>
              </div>
            </div>
          </ng-container>
        </div>
        <ng-template #elseBlock>
          <p class="mb-0 text-center font-18 font-bold mt-5">No Video Found</p>
        </ng-template>
      </div>
    </div>
  </div>
  <div class="col-md-9 right-part bg-white overflow-auto">
    <div class="card mb-0">

      <div class="bg-white">
        <div class="text-center" *ngIf="selectedVideo && selectedVideo['edu_master_file.path']">
          <app-media-viewer [type]="'video'" [key]="selectedVideo['edu_master_file.path']"></app-media-viewer>

        </div>
      </div>
    </div>
  </div>
</div>

import { Injectable } from '@angular/core';
import { action, observable, computed, autorun } from 'mobx';
import { cloneDeep } from 'lodash';
@Injectable({ providedIn: 'root' })
export class ProfileStore {
    @observable userData: any;
    @observable userToken: string;
    constructor() {
        if (window.localStorage['userData']) {
            let user = JSON.parse(localStorage["userData"]);
            this.userData = Object.assign({}, user);
            this.userToken = this.userData.sessionToken ? this.userData.sessionToken : null;
        }
        autorun(() => {
            if (this.userData) {
                window.localStorage.setItem('userData', JSON.stringify(this.userData))
            }
            if (window.localStorage['userData']) {
                let user = JSON.parse(localStorage["userData"]);
                this.userData = Object.assign({}, user);
                this.userToken = this.userData.sessionToken ? this.userData.sessionToken : null;
            }
        })
    }


    @action setUser(data: any) {
        this.userData = cloneDeep(data);
        this.userToken = this.userData.sessionToken ? this.userData.sessionToken : null;
        //   this.userData.toViewModel(data);
        window.localStorage.setItem('userData', JSON.stringify(this.userData));
    }

    @computed get getprofilePic() {
        return this.userData && this.userData.photo ? this.userData.photo : 'assets/images/icon/staff.png'
    }

    @action haveAccess(keyToCheck: string): boolean {
        // console.log('key check', keyToCheck);
        if (this.userData && this.userData.role == 'admin') {
            
            return true;
        }
        else {
            
            if (this.userData && this.userData.priviliges && this.userData.priviliges.length > 0) {
                return this.userData.priviliges.some(userRight => userRight.title == keyToCheck);
            }else{
              
                console.log("not accessable")
            }
        }
    }

    @action reset() {
        this.userData = null;
        this.userToken = null;
        localStorage.removeItem('userData');
        localStorage.removeItem('files');
        localStorage.removeItem('fbToken');
        // window.localStorage.clear();
    }

}
export class GlobalDialogModel {
    id: string;
    dialogType?: string;
    heading?: string
    description?: string
    inputHeading?: string;
    inputLength?: number;
    actionButtonText?: string
    secondActionButtonText?: string
    detailsArray?: Array<string>
    selectedData?: any;
    inputEmptyError?: string;
    image?: any

    constructor() {
        this.id = '';
        this.dialogType = '';
        this.heading = '';
        this.description = '';
        this.heading = '';
        this.actionButtonText = '';
        this.secondActionButtonText = '';
        this.image = ""
        this.inputHeading = ""
        this.inputEmptyError = null
    }
}
import { environment } from "src/environments/environment";

export const requests = {
    signup: environment.url + "/register",
    getmedialibrary: environment.url + "/media-library",
    login: environment.url + "/login",
    action: environment.url + '/action',
    shareJob: environment.url + '/incrementShareCount',
    unauthorizedAction: environment.url + '/viewActionDetails',
    tableView: environment.url + '/tableView',
    getForm: environment.url + '/getForm',
    createEdit: environment.url + '/createEdit',
    getFilter: environment.url + '/getFilters',
    uploadFile: environment.url + '/uploadfile',
    s3FileProxy: environment.url + '/s3fileproxy',
    fillData: environment.url + '/fillData',
    delete: environment.url + '/delete',
    updateStatus: environment.url + '/updateStatus',
    logout: environment.url + '/logout',
    changePassword: environment.url + '/changePassword',
    dashboard:environment.url + '/dashboard',
    tableViewLanding:environment.url + '/currentPositions',
    getFilterLanding:environment.url + '/filters',
    getFormLanding:environment.url + '/viewDetails',
    getSettings:environment.url + '/settings',
    getQuestions:environment.url + '/questions',
    updateQuestion:environment.url + '/updateQuestion',
    updateSetting:environment.url + '/updateSetting',
    getNotifications:environment.url + '/getnotifications',
    readNotifications:environment.url + '/readnotifications',
    pdStatus:environment.url + '/pdstatus',
    fbToken:environment.url + '/insertFBT',
    socialSignup:environment.url+'/socialSignup',
    socialRegistration:environment.url+'/socialRegistration',
    // linkedinLogin:environment.url+'/linkedin',
    // linkedInregistration:environment.url+'/linkedinregistration',
    recoverPassword:environment.url+'/forgotpassword',
    resendEmail:environment.url+'/reSendVerifyEmail',
    forgotchangepassword:environment.url+'/forgotchangepassword',
    verifyEmail:environment.url+'/verifyemail',
    getUsers:environment.url+'/listusers',
    registerOrgUser:environment.url+'/addorguser',
    deleteOrgUser:environment.url+'/deleteorguser',
    changeStatusUser:environment.url+'/changeStatusUser',
    setPassword:environment.url+'/setPassword',
    editOrgUser:environment.url+'/editorguser',
    reviewEmployeeSearch:environment.url+'/reviewEmployee',
    reviewEmployerSearch:environment.url+'/reviewEmployers',
    myJobsIntelliscense:environment.url+'/myjobs',
    exportApi:environment.url+'/userdata',

    verifyPayment:environment.url+'/verifyPayment',
    manualPaymentInitiated:environment.url+'/manualPaymentInitiated',
    notifyByAdmin:environment.url+'/notify-by-admin',
    getBlogs:environment.url+'/blogs',
    getBlog:environment.url+'/blogById/',
    createBlog:environment.url+'/addBlog',
    createComment:environment.url+'/addComment',
    deleteBlog:environment.url+'/deleteBlog/',
    deleteComment:environment.url+'/deleteComment/',
    profilePercentage:environment.url+'/resumePercentage',
    getAccessTokenLinkedin: environment.url+'/getLinkedlnAccessToken',
    publishPostLinkedin: environment.url+'/publishLinkedInPost',
    getAccessTokenTwitter:  environment.url+'/getTwitterAccessToken',
    publishPostTwitter: environment.url+'/publishTwitterPost',
    getAccessTokenFacebook: environment.url+'/getFacebookAccessToken',
    publishPostFacebook: environment.url+'/publishFacebookToken',
    getPageAccessTokenFacebook: environment.url+'/getFacebookPageAccessTokens',
    getInstagramAccounts: environment.url+'/getInstagramAccounts',
    getInstagramBusiness: environment.url+'/getInstagramBusiness',
    publishPostInstagram: environment.url+'/publishPostInstagram',
    getEmailSendingUsers: environment.url+'/getSubscribedEmployees',
    sendEmailToUsers: environment.url+'/sendEmployeeEmail',
    unsubscribe: environment.url+'/unsubscribe'
}
// twitterApiKey = 7E5AEGPRG8LBLpFQ9R7Rrm2rp
// twitterApiKeySecret = owj7qXYy36eTA4Gae6HMakKnVKw7o64WopdIPM6sOBy2gWDEez
// twitterbearertoken = AAAAAAAAAAAAAAAAAAAAAHXBtwEAAAAA2PQGzxucr94k0Wsmf7aitYwR0rI%3D0uXDIpuN3maXrYpx3xIYYVbWWfmQXLY8lVb2SaRkGvSTIbC2ow
// twitterclientid = eF9HdWZyM2tsUzVwV3RxRUpvWGk6MTpjaQ
// twitterclientSecret = bTRGoDKwaIzH8QS3IU601UgH5ss-DT_4zGZF-INCWB30Bj_6qR

export class TableViewRequest<S extends string = string>{
    params?: { tableName: string, fields?: Array<any> } | any;
    filter?: Object;
    sort?: Sort<S>;
    pagination?: Pagination;
}
export type Pagination = { offset: number, limit: number }
export type SortItem<T> = [T, 'asc' | 'desc'];

export type Sort<T> = Array<SortItem<T>>;

// Response types

export type ResponseTable<T> = {
    message: string
    result: { attributes: Array<any>, count: number, rows: Array<any> };
};
<div class="news-header" *ngIf="(detectAndroidMob() || detectAppleMob()) && !dismissMobile">
  <p>Download EduOpenings for   <strong *ngIf="detectAndroidMob()"> <a href="https://play.google.com/store/apps/details?id=com.edumobileapp">Andriod devices</a></strong>
    <strong *ngIf="detectAppleMob()"> <a href="https://apps.apple.com/us/app/eduopenings/id1579674351">Apple devices</a></strong></p>
</div>


  <!-- ========== Loader Start  =================================-->
  <div class="preloader" *ngIf="commonStore.loader" style="background: aliceblue; opacity: 0.6;z-index: 10000;">
    <div class="spinner">
      <!-- Loading..... -->
      <div class="double-bounce1"></div>
      <div class="double-bounce2"></div>
    </div>
  </div>

  <!-- ==============  Loader End ============================= -->

  <!-- ============================================================== -->
  <!-- Main wrapper - style you can find in pages.scss -->
  <!-- ============================================================== -->

  <div id="main-wrapper" [ngClass]="{'show-sidebar': showMobileMenu}" [dir]="options.dir"
    [attr.data-theme]="options.theme" [attr.data-layout]="options.layout" [attr.data-sidebartype]="options.sidebartype"
    [attr.data-sidebar-position]="options.sidebarpos" [attr.data-header-position]="options.headerpos"
    [attr.data-boxed-layout]="options.boxed">
    <!-- ===============Alert Modal=================== -->
    <div *ngIf="(commonStore.alertArray && commonStore.alertArray.length > 0)" class="top-alert">
      <div class="d-flex justify-content-center" *ngFor="let alert of commonStore.alertArray" (click)="closeAlert(alert)">
        <!-- <button type="button" class="close" (click)="closeAlert(alert)"><span area-hidden="true">&times;</span></button> -->
        <div *ngIf="alert.isOpen" [ngClass]="'toastr-'+alert.case" class="toastr p-3">
  
          <div class="row" *ngIf="alert.case=='success'">
            <div class="col-sm-3">
              <i class="mdi mdi-check-circle-outline icon-tick" style="font-size: 40px;"></i>
            </div>
            <div class="col-sm-9 text-left">
              <h4 class="mb-0">Operation Completed Successfully</h4>
              <p class="mb-0" *ngIf="alert && alert.message && alert.local">
                {{alert.message}}
              </p>
            </div>
          </div>
  
          <div class="row" *ngIf="alert.case=='error'">
            <div class="col-sm-3">
              <i class="mdi mdi-alert" style="font-size: 40px;"></i>
            </div>
            <div class="col-sm-9 text-left">
              <h3 class="mb-0">Error Occured</h3>
              <p class="mb-0" *ngIf="alert && alert.message">
                {{alert.message}}
              </p>
            </div>
          </div>
  
          <div class="row" *ngIf="alert.case=='info'">
            <div class="col-sm-3">
              <i class="mdi-alert-circle-outline" style="font-size: 40px;"></i>
            </div>
            <div class="col-sm-9 text-left">
              <h3 class="mb-0">Info</h3>
              <p class="mb-0" *ngIf="alert && alert.message">
                {{alert.message}}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- ======================Alert Model end==================== -->

    <!-- ============================================================== -->
    <!-- Topbar header - style you can find in topbar.scss -->
    <!-- ============================================================== -->
    <header class="topbar" [attr.data-navbarbg]="options.navbarbg">
      <nav class="d-flex top-navbar navbar-expand-md"
        [ngClass]="(options.navbarbg=='skin6')?'navbar-light':'navbar-dark'">
        <div class="navbar-header" [ngClass]="(expandLogo)?'expand-logo':''" [attr.data-logobg]="options.logobg">
          <a (click)="showMobileMenu = !showMobileMenu" class="nav-toggler waves-effect waves-light d-block d-md-none"
            href="javascript:void(0)">
            <i [ngClass]="(showMobileMenu)?'ti-close':'ti-menu'"></i>
          </a>
          <!-- ============================================================== -->
          <!-- Logo -->
          <!-- ============================================================== -->
          <a class="navbar-brand" href="/">
            <!-- Logo icon -->
            <b class="logo-icon" style="height: 60px;">
              
              <img src="assets/images/logo-icon.jpg" alt="homepage" class="dark-logo" />
              <!-- Light Logo icon -->
              <img src="assets/images/logo-icon.jpg" alt="homepage" class="light-logo" />
            </b>
            <span class="logo-text">
              <img src="assets/images/logo-text.jpg" alt="homepage" class="dark-logo" />
              <!-- Light Logo text -->
              <img src="assets/images/logo-text.jpg" class="light-logo" alt="homepage" />
            </span>
          </a>
          <!-- ============================================================== -->
          <!-- End Logo -->
          <!-- ============================================================== -->
          <!-- ============================================================== -->
          <!-- Toggle which is visible on mobile only -->
          <!-- ============================================================== -->
          <a class="topbartoggler d-block d-md-none waves-effect waves-light" href="javascript:void(0)"
            (click)="isCollapsed = !isCollapsed" [attr.aria-expanded]="!isCollapsed"
            data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent">
            <i class="ti-more"></i>
          </a>
        </div>
        <!-- ============================================================== -->
        <!-- End Logo -->
        <!-- ============================================================== -->
        <div class="navbar-collapse collapse" id="navbarSupportedContent" [attr.data-navbarbg]="options.navbarbg"
          [ngbCollapse]="!isCollapsed">
          <app-navigation (toggleSidebar)="toggleSidebarType()" class="w-100"></app-navigation>
        </div>
      </nav>
    </header>
    <!-- ============================================================== -->
    <!-- Left Sidebar - style you can find in sidebar.scss  -->
    <!-- ============================================================== -->
    <aside class="left-sidebar" [attr.data-sidebarbg]="options.sidebarbg" (mouseover)="Logo()" (mouseout)="Logo()">
      <!-- Sidebar scroll-->
      <div class="scroll-sidebar" [perfectScrollbar]="config">
        <app-sidebar></app-sidebar>
      </div>
      <!-- End Sidebar scroll-->
    </aside>
    <!-- ============================================================== -->
    <!-- End Left Sidebar - style you can find in sidebar.scss  -->
    <!-- ============================================================== -->
    <!-- ============================================================== -->
    <!-- Page wrapper  -->
    <!-- ============================================================== -->
    <div class="page-wrapper">
      <app-breadcrumb></app-breadcrumb>
      <!-- ============================================================== -->
      <!-- Container fluid  -->
      <!-- ============================================================== -->
      <div class="container-fluid">
        <!-- ============================================================== -->
        <!-- Start Page Content -->
        <!-- ============================================================== -->
        <router-outlet></router-outlet>
        <!-- ============================================================== -->
        <!-- End Start Page Content -->
        <!-- ============================================================== -->
      </div>
      <!-- ============================================================== -->
      <!-- End Container fluid  -->
      <!-- ============================================================== -->
      <!-- ============================================================== -->
      <!-- footer -->
      <!-- ============================================================== -->
      <footer class="footer">
        © {{year}} Edu Openings
      </footer>
      <!-- ============================================================== -->
      <!-- End footer -->
      <!-- ============================================================== -->
    </div>
    <!-- ============================================================== -->
    <!-- End Page wrapper  -->
    <!-- ============================================================== -->


  </div>

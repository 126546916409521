import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { TimeZone } from './zone.model';
import * as moment from "moment-timezone";
import { find } from "lodash";
declare var  $:any;
@Component({
  selector: 'timezone',
  templateUrl: './timezone.component.html',
  styleUrls: ['./timezone.component.scss']
})
export class TimezoneComponent implements OnInit {
  timeZoneList:Array<any> = [];
  @Input() currentTimeZone:TimeZone;
  @Output() selectedTimezone:EventEmitter<TimeZone> = new EventEmitter<TimeZone>();
  constructor() { }
  datePicker() {
    $('#datetimepicker1').datetimepicker();
  }
  ngOnInit() {
    let timeZoneList = moment.tz.names();
    for (var i in timeZoneList) {
      this
        .timeZoneList
        .push({
          "offset": moment
            .tz(timeZoneList[i])
            .format('Z'),
          "timezone": timeZoneList[i]
        });    
          if ( timeZoneList[i] == this.currentTimeZone.timezone) {
            this.currentTimeZone = {"offset": moment.tz( timeZoneList[i]).format('Z'),"timezone":  timeZoneList[i]}
          }
        
    }
  }
  changeTimeZone = (timeZone) =>{
   this.selectedTimezone.emit( find(this.timeZoneList, ['timezone', timeZone]));
    }
}
